import React, { Component } from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import Modal from 'react-responsive-modal';

import 'react-responsive-modal/styles.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard } from '@fortawesome/free-solid-svg-icons'
library.add(faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard)

const CancelToken = axios.CancelToken;
let cancel;

export default class HelpButton extends Component {

  constructor(props){
    super(props)

    this.state = {
      isShowingModal: false,
      email: '',
      comment: '',
      sendIsDisabled: false,
      buttonText: 'Send'
    }
  }

  showModal = () => {
    this.setState({isShowingModal: true})
  }
  handleClose = () => {
    this.setState({isShowingModal: false,email:'',comment:''})
  }
  handleEmailChange = (e) => {
    this.setState({email: e.target.value});
  }
  handleCommentChange = (e) => {
    this.setState({comment: e.target.value});
  }

  sendEmail = (email, comment) => {
    let query_data = {
      'body': comment,
      'email': email,
      'reason': 'help',
    }

    this.setState({sendIsDisabled: true});
    
    axios.post(this.props.apiBaseURL + 'send_email', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {

      this.setState({buttonText: 'Help Request Sent'});
      setTimeout(() => {
        this.handleClose()
        this.setState({sendIsDisabled: false, buttonText: 'Send'});
      }, 2000);

    }).catch((reason: AxiosError) => {

      this.setState({buttonText: 'Error Sending Request'});
      setTimeout(() => {
        this.handleClose()
        this.setState({sendIsDisabled: false, buttonText: 'Send'});
      }, 2000);
    })
    
  }

  render() {
    return(
      <div>
        <div className="footer">
          <p>&copy; ARM DQ Office | <a onClick={this.showModal} href="#">Need Help?</a></p>
        </div>
        <Modal open={this.state.isShowingModal} onClose={this.handleClose} classNames={{ overlay: 'share-overlay', modal: 'share-modal', closeButton:'share-close-button', closeIcon:'share-close-icon'}} center>
          <div style={{minWidth:250}}>
            <div >
              <h3 style={{margin:0, paddingBottom:20}}>DQ Zoom Help</h3>
            </div>
            <div>
              <p style={{margin:5}}><b>Email Address:</b></p>
              <input onChange={this.handleEmailChange}  placeholder="Enter Email..." value={this.state.email} className='help-modal-input'/>

              <br/>
              <br/>

              <p style={{margin:5}}><b>Description/Comment:</b></p>
              <textarea onChange={this.handleCommentChange} placeholder="Describe the issue or leave a comment..." style={{height:70}} value={this.state.comment} className='help-modal-input'/>

              <br/>
              <br/>

              <button disabled={this.state.sendIsDisabled} onClick={()=>this.sendEmail(this.state.email, this.state.comment)} className="plot-menu-button">{this.state.buttonText}</button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}