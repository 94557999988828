import React, { Component } from 'react';
import ReactDOM from "react-dom";
import Plot from 'react-plotly.js';


const plotOptions = {
  scrollZoom: true, 
  displaylogo: false,
  modeBarButtonsToRemove: ['sendDataToCloud','autoScale2d'],
}

export default class InteractivePlot extends Component {

  render() {
    return(
      <div className='plot-area'>
        <div style={{height:'100%'}}>
          <div style={ this.props.plotData.length <= 1 ? {height:'calc(100% - 200px)'} : {height:'100%'}}>
            <Plot
              // revision={this.state.revision}
              onRelayout={this.props.onRelayout}
              onAfterPlot={this.props.onAfterPlot}
              onClick={this.props.onPlotClick}
              onLegendDoubleClick={this.props.onLegendDoubleClick}
              data={this.props.plotData}
              layout={this.props.plotLayout}
              revision={this.props.plotLayout.datarevision}
              config={plotOptions}
              useResizeHandler={true}
              style={{width: "100%", height: "100%"}}
            />
          </div>
          { this.props.plotData.length <= 1 ?
            <div style={{width:'100%', height:'20%'}}>
              <div style={{display:'inline-block',width: 'calc(100% - 190px)', height:'100%', float:'left'}}>
                <Plot
                  data={this.props.histData}
                  layout={this.props.histLayout}
                  config={{displayModeBar:false}}
                  useResizeHandler={true}
                  style={{width: "100%", height: "200px"}}
                />
              </div>
              
              <div style={{display:'inline-block', width:150, padding: '0px 20px 0px 20px', height:'100%', float:'right'}}>
                <StatsDisplay plotIsActive={this.props.plotIsActive} displayFileHeaderWindow={this.props.displayFileHeaderWindow} stats={this.props.dataStats}/>
              </div>

            </div>
            :
              null
            }
        </div>
      </div>
    );
  }
}



class StatsDisplay extends Component {

  render() {
    return(
      <div>
        <button disabled={!this.props.plotIsActive} onClick={this.props.displayFileHeaderWindow} className='file-header-button'>Show File Header</button>
        <br/>
        <br/>
        <span className='stats-label'>Min: </span><span className='stats-value'>{this.props.stats.min}</span>
        <br/>
        <span className='stats-label'>Max: </span><span className='stats-value'>{this.props.stats.max}</span>
        <br/>
        <span className='stats-label'>Mean: </span><span className='stats-value'>{this.props.stats.mean}</span>
        <br/>
        <span className='stats-label'>Stdev: </span><span className='stats-value'>{this.props.stats.stdev}</span>
      </div>
    )
  }
}
