import React, { Component } from 'react';
import ReactDOM from "react-dom";

import NewWindow from 'react-new-window';

export default class FileHeaderWindow extends Component {
  render() {
    return(
      this.props.isOpen 
      ?
      <NewWindow onUnload={this.props.onClose} copyStyles={true} title='NetCDF File Header' name='header_window'>
        <p style={{whiteSpace: 'pre-wrap', padding: 20}} >
          {this.props.header}
        </p>
      </NewWindow>
      :
      null
    )
  }

}