import React, { Component } from 'react';
import ReactDOM from "react-dom";
import moment from 'moment';
import axios from 'axios';
import Select from 'react-select';
import Toggle from 'react-toggle'
import Modal from 'react-responsive-modal';
import Clipboard from 'react-clipboard.js';
import { Tooltip } from 'react-tippy';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { Scrollbars } from 'react-custom-scrollbars';

import logo from '../img/ARM_Logo_2017reverse.png';

import 'react-toggle/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-responsive-modal/styles.css';
import 'react-tippy/dist/tippy.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard, faInfoCircle, faHeart} from '@fortawesome/free-solid-svg-icons'
library.add(faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard, faInfoCircle, faHeart)


var qs = require('qs');


// Define all constants to be used throughout the app
const customSelectStyles = {
  control: (base, state) => ({
    ...base,
    'border': state.isFocused ? 'none' : 'none',
    'box-shadow': state.isFocused ? 'none' : 'none',
    'cursor': 'pointer'
  }),
  option: (base, state) => ({
    ...base,
    'cursor': 'pointer',
    'backgroundColor': state.isDisabled ? null: state.isSelected ? 'rgba(75, 95, 115, 0.2)' : state.isFocused ? 'rgba(75, 95, 115, 0.05)' : null,
    'color': '#000000'
  })
}

const goodColor = '#00BD70'
const okColor   = '#5b9bc3'
const badColor  = '#c35b5b'


const CancelToken = axios.CancelToken;
let cancel;

export default class PlotSelectMenu extends Component {
   constructor(props) {
    super(props);

    if(this.props.hasQueryString){
      this.props.handleStartLoadFromQueryString()
    }

    this.state = {
      dsTree:             {},
      datastreams:        [],
      selectedDatastream: '',
      dsLoaded:           false,
      sites:              [],
      selectedSite:       '',
      classes:            [],
      selectedClass:      '',
      levels:             [],
      selectedFacility:   '',
      facilities:         [],
      selectedLevel:      '',
      variables:          [],
      selectedVariable:   '',
      variableInfoText:   'Select a date to get available variables',
      variableInfoTextColor:  okColor,
      coordVars:          [],
      selectedCoordVar:   '',
      coordVarLabel:      '',
      coordVarDim:        '',
      coordVarData:       {},
      has2D:              false,
      hasQC:              false,
      startDate:          this.props.hasQueryString && !this.props.queryStringObj.sdate.includes("$latest") ? moment(this.props.queryStringObj.sdate) : moment().startOf('day'),
      endDate:            this.props.hasQueryString && !this.props.queryStringObj.edate.includes("$latest") ? moment(this.props.queryStringObj.edate) : moment().startOf('day'),
      dates:              [],
      dateInfoText:       'Select a datastream to get available dates',
      dateInfoTextColor:  okColor,
      genPlotsIsDisabled: true,
      qcEnabled:          false,
      qcSelection:        'all-overlay',
    }

    this.handleDsChange       = this.handleDsChange.bind(this);
    this.handleSiteChange     = this.handleSiteChange.bind(this);
    this.handleClassChange    = this.handleClassChange.bind(this);
    this.handleFacilityChange = this.handleFacilityChange.bind(this);
    this.handleLevelChange    = this.handleLevelChange.bind(this);
    this.handleDateChange     = this.handleDateChange.bind(this);
    this.handleDateSwitch     = this.handleDateSwitch.bind(this);
    this.handleVariableChange = this.handleVariableChange.bind(this);
    this.handleCoordVarChange = this.handleCoordVarChange.bind(this);
    this.handleQCToggle       = this.handleQCToggle.bind(this);
    this.handleQCRadioChange  = this.handleQCRadioChange.bind(this);
    this.getDatastreams       = this.getDatastreams.bind(this);
    this.getDates             = this.getDates.bind(this);
    this.getVariables         = this.getVariables.bind(this);
    this.loadOptions          = this.loadOptions.bind(this);
    this.decomposeDatastream  = this.decomposeDatastream.bind(this);
    this.genPlots             = this.genPlots.bind(this)
    this.addPlot              = this.addPlot.bind(this)
  }

  componentDidMount() {
    let parent = this
    let interval = setInterval(function() {
      console.log(parent.props.componentLoaded)
      if(parent.props.componentLoaded){
        clearInterval(interval)
        console.log("here")
        parent.getDatastreams()
      }
    }, 200)
    
  }

  handleDsChange(selectedOption){

    if(selectedOption.length !== 0){

      let selectedSite     = this.decomposeDatastream(selectedOption.value)['site']
      let selectedClass    = this.decomposeDatastream(selectedOption.value)['instrument']
      let selectedFacility = this.decomposeDatastream(selectedOption.value)['facility']
      let selectedLevel    = this.decomposeDatastream(selectedOption.value)['data_level']

      let tempSites        = Object.keys(this.state.dsTree)
      let tempClasses      = Object.keys(this.state.dsTree[selectedSite])
      let tempFacilities   = Object.keys(this.state.dsTree[selectedSite][selectedClass])
      let tempLevels       = this.state.dsTree[selectedSite][selectedClass][selectedFacility]



      this.setState({
        selectedDatastream:selectedOption,
        sites: tempSites.map((s, i) => ({ value: s, label: s })),
        classes: tempClasses.map((c, i) => ({ value: c, label: c })),
        facilities: tempFacilities.map((f, i) => ({ value: f, label: f })),
        levels: tempLevels.map((l, i) => ({ value: l, label: l })),
        selectedSite: { value: selectedSite, label: selectedSite },
        selectedClass: { value: selectedClass, label: selectedClass },
        selectedFacility: { value: selectedFacility, label:  selectedFacility },
        selectedLevel: { value: selectedLevel, label: selectedLevel },

      })
    }
    else{
      this.setState({
        selectedDatastream:selectedOption,
      })
    }
  }


  handleSiteChange(selectedOption){
    if(selectedOption.length !== 0){

      let selectedSite     = selectedOption.value

      let tempClasses      = Object.keys(this.state.dsTree[selectedSite])
      let selectedClass    = tempClasses[0]

      let tempFacilities   = Object.keys(this.state.dsTree[selectedSite][selectedClass])
      let selectedFacility = tempFacilities[0]

      let tempLevels       = this.state.dsTree[selectedSite][selectedClass][selectedFacility]
      let selectedLevel    = tempLevels[0]

      let selectedDatastream = selectedSite.toLowerCase() + selectedClass + selectedFacility + '.' + selectedLevel


      this.setState({
        selectedSite: selectedOption,
        classes: tempClasses.map((c, i) => ({ value: c, label: c })),
        facilities: tempFacilities.map((f, i) => ({ value: f, label: f })),
        levels: tempLevels.map((l, i) => ({ value: l, label: l })),
        selectedClass: { value: selectedClass, label: selectedClass },
        selectedFacility: { value: selectedFacility, label:  selectedFacility },
        selectedLevel: { value: selectedLevel, label: selectedLevel },
        selectedDatastream: { value: selectedDatastream, label: selectedDatastream },
      })
    }
    else{
      this.setState({
        selectedSite: selectedOption,
        classes: [],
        facilities: [],
        levels: [],
        selectedClass: '',
        selectedFacility: '',
        selectedLevel: '',
        selectedDatastream: '',
      })
    }
  }

  handleClassChange(selectedOption){
    if(selectedOption.length !== 0){
      let selectedSite     = this.state.selectedSite.value
      let selectedClass    = selectedOption.value

      let tempFacilities   = Object.keys(this.state.dsTree[selectedSite][selectedClass])
      let selectedFacility = tempFacilities[0]

      let tempLevels       = this.state.dsTree[selectedSite][selectedClass][selectedFacility]
      let selectedLevel    = tempLevels[0]

      let selectedDatastream = selectedSite.toLowerCase() + selectedClass + selectedFacility + '.' + selectedLevel


      this.setState({
        facilities: tempFacilities.map((f, i) => ({ value: f, label: f })),
        levels: tempLevels.map((l, i) => ({ value: l, label: l })),
        selectedClass: selectedOption,
        selectedFacility: { value: selectedFacility, label:  selectedFacility },
        selectedLevel: { value: selectedLevel, label: selectedLevel },
        selectedDatastream: { value: selectedDatastream, label: selectedDatastream },
      })
    }
    else{
      this.setState({
        facilities: [],
        levels: [],
        selectedClass: selectedOption,
        selectedFacility: '',
        selectedLevel: '',
        selectedDatastream: '',
      })
    }
  }

  handleFacilityChange(selectedOption){

    if(selectedOption.length !== 0){
      let selectedSite     = this.state.selectedSite.value
      let selectedClass    = this.state.selectedClass.value
      let selectedFacility = selectedOption.value

      let tempLevels       = this.state.dsTree[selectedSite][selectedClass][selectedFacility]
      let selectedLevel    = tempLevels[0]

      let selectedDatastream = selectedSite.toLowerCase() + selectedClass + selectedFacility + '.' + selectedLevel

      this.setState({
        levels: tempLevels.map((l, i) => ({ value: l, label: l })),
        selectedFacility: selectedOption,
        selectedLevel: { value: selectedLevel, label: selectedLevel },
        selectedDatastream: { value: selectedDatastream, label: selectedDatastream },
      })
    }
    else{
      this.setState({
        levels: [],
        selectedFacility: selectedOption,
        selectedLevel: '',
        selectedDatastream: '',
      })
    }
  }

  handleLevelChange(selectedOption){
    if(selectedOption.length !== 0){
      let selectedSite     = this.state.selectedSite.value
      let selectedClass    = this.state.selectedClass.value
      let selectedFacility = this.state.selectedFacility.value
      let selectedLevel    = selectedOption.value

      let selectedDatastream = selectedSite.toLowerCase() + selectedClass + selectedFacility + '.' + selectedLevel

      this.setState({
        selectedLevel: selectedOption,
        selectedDatastream: { value: selectedDatastream, label: selectedDatastream },
      })
    }
    else{
      this.setState({
        selectedLevel: selectedOption,
        selectedDatastream: '',
      })
    }
  }

  handleDateSwitch(type){
    if(type === 'starttoend'){
      this.setState({ 
        endDate: this.state.startDate,
      },() => {
        this.setState({genPlotsIsDisabled:true})
        this.getVariables(this.state.selectedDatastream.value,  moment(this.state.endDate).format('YYYY/MM/DD'))
      })
    }
    else{
      this.setState({ 
        startDate: this.state.endDate,
      },() => {
        this.setState({genPlotsIsDisabled:true})
        this.getVariables(this.state.selectedDatastream.value,  moment(this.state.endDate).format('YYYY/MM/DD'))
      })
    }
  }

  handleDateChange({startDate, endDate}){
    startDate = startDate || this.state.startDate
    endDate   = endDate   || this.state.endDate

    if (moment(startDate).isAfter(moment(endDate))) {
      endDate = startDate
    }

    this.setState({ 
      startDate: startDate, 
      endDate: endDate,
    },() => {
      this.setState({genPlotsIsDisabled:true})
      this.getVariables(this.state.selectedDatastream.value,  moment(this.state.endDate).format('YYYY/MM/DD'))
    })
  }

  isValidDate = (dateString) => {
    var regEx = /\d{4}\d{2}\d{2}$/;
    return dateString.match(regEx) != null;
  }

  handleVariableChange(selectedOption){

    let hasQC = false
    let has2D = false
    let coordVarLabel = ''
    let coordVarDim= ''
    let coordVars = []


    if(selectedOption.value in this.state.coordVarData){
      has2D = true
      coordVarLabel = this.state.coordVarData[selectedOption.value].coord_label
      coordVars = this.state.coordVarData[selectedOption.value].coord_data.map((v, i) => ({ value: v, label: v }))
      coordVarDim = this.state.coordVarData[selectedOption.value].coord_dim
    }

    if(has2D){
      coordVars.unshift({ value: 'all', label: 'ALL (2D Plot)' })
    }

    for(let i=0; i<this.state.variables.length; i++){
      if(this.state.variables[i].value === 'qc_'+selectedOption.value && ! has2D){
        hasQC = true
      }
    }

    
    let tempSelectedCoordVar = coordVars[0]
    if(this.props.hasQueryString){

      if(this.props.queryStringObj.coordinate == 'all'){
        tempSelectedCoordVar = { value: this.props.queryStringObj.coordinate, label: this.props.queryStringObj.coordinate.toUpperCase() + ' (2D Plot)' }
      }
      else{
        if(has2D && this.props.queryStringObj.coordinate == ''){
          tempSelectedCoordVar = { value: 'all', label: 'ALL (2D Plot)' }
        } 
        else{
          tempSelectedCoordVar = { value: this.props.queryStringObj.coordinate, label: this.props.queryStringObj.coordinate }
        }
        
      }
    }

    this.setState({
      hasQC: hasQC,
      has2D: has2D,
      selectedVariable: selectedOption,
      coordVarLabel: coordVarLabel,
      coordVarDim: coordVarDim,
      selectedCoordVar:tempSelectedCoordVar,
      coordVars: coordVars
    }, () => {
      if(this.props.hasQueryString){
        // setTimeout(function(){ document.getElementsByClassName('plot-menu-button')[0].click() }, 3000);
        this.genPlots(() => {
          if ('addvariables' in this.props.queryStringObj) {
            let addVariables = this.props.queryStringObj.addvariables.replace(/[\[\]']+/g,'').split(",")

            // add additional vars to plot if requested
            for (let i=0; i<addVariables.length;i++) {
              let addVar = addVariables[i].split(":")

              let addVarStartDate  = moment(this.state.startDate).format('yyyy-MM-DD')
              let addVarEndDate    = moment(this.state.endDate).format('yyyy-MM-DD')
              let addVarCoord      = ""
              let addVarCoordDim   = ""

              if (addVar.length > 2){

                if(this.isValidDate(addVar[2]) && this.isValidDate(addVar[3])) {
                  addVarStartDate = moment(addVar[2]).format('yyyy-MM-DD')
                  addVarEndDate   = moment(addVar[3]).format('yyyy-MM-DD')

                  if (addVar.length > 4) {
                    addVarCoord     = addVar[4]
                    addVarCoordDim  = addVar[5]
                  }
                }

                else {
                  if (addVar.length == 4) {
                    addVarCoord     = addVar[2]
                    addVarCoordDim  = addVar[3]
                  }
                }
                
              }

              let reqData = {
                'ds':addVar[0],
                'sdate': addVarStartDate,
                'edate': addVarEndDate,
                'variable': addVar[1],
                'drop_variables': [],
                'coordinate': addVarCoord,
                'qc_check': '',
                'coord_dim': addVarCoordDim,
              }

              if (addVar.length > 4) {
                reqData.coordinate = addVar[4]
                reqData.coord_dim = addVar[5]
              }

              this.props.generatePlot(reqData,false, cancel, true)
            }
          }
        })
        this.props.disableLoadFromQueryString()
      }
    })
  }

  handleCoordVarChange(selectedOption){
    this.setState({
      selectedCoordVar: selectedOption,
    })
  }

  handleQCToggle(){
    this.setState({
      qcEnabled: !this.state.qcEnabled
    })
  }

  handleQCRadioChange(changeEvent){
    this.setState({
      qcSelection: changeEvent.target.value
    });
  }

  getDatastreams(){
    axios.get(this.props.apiBaseURL + 'get_datastreams_celery', {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message);
      } else {
        console.log(thrown.message)
        // handle error
      }
    }).then(res => {
      if(typeof(res) !== "undefined") {
        let result = res.data
        let task_id = result.task_id

        this.props.checkTaskStatus(task_id, (result) => {

          let dsOptions = result.map((ds, i) => ({ value: ds, label: ds }))
          let tempDsTree = {}

          for (let i=0; i<result.length; i++){

              let ds_parts = this.decomposeDatastream(result[i])
              let site = ds_parts["site"];
              let instrument = ds_parts["instrument"];
              let facility = ds_parts["facility"];
              let data_level = ds_parts["data_level"];

              

              if (!tempDsTree[site]) tempDsTree[site] = {};
              if (!tempDsTree[site][instrument]) tempDsTree[site][instrument] = {};
              if (!tempDsTree[site][instrument][facility]) tempDsTree[site][instrument][facility] = [];
              if (-1 === tempDsTree[site][instrument][facility].indexOf(data_level)) tempDsTree[site][instrument][facility].push(data_level);

          }


          let selectedDs       = this.props.hasQueryString ? { value: this.props.queryStringObj.ds, label: this.props.queryStringObj.ds } : dsOptions[0]

          let selectedSite     = this.decomposeDatastream(selectedDs.value)['site']
          let selectedClass    = this.decomposeDatastream(selectedDs.value)['instrument']
          let selectedFacility = this.decomposeDatastream(selectedDs.value)['facility']
          let selectedLevel    = this.decomposeDatastream(selectedDs.value)['data_level']

          let tempSites        = Object.keys(tempDsTree)
          let tempClasses      = Object.keys(tempDsTree[selectedSite])
          let tempFacilities   = Object.keys(tempDsTree[selectedSite][selectedClass])
          let tempLevels       = tempDsTree[selectedSite][selectedClass][selectedFacility]


          this.setState({
            dsTree:tempDsTree,
            datastreams:dsOptions,
            selectedDatastream:selectedDs,
            sites: tempSites.map((s, i) => ({ value: s, label: s })),
            classes: tempClasses.map((c, i) => ({ value: c, label: c })),
            facilities: tempFacilities.map((f, i) => ({ value: f, label: f })),
            levels: tempLevels.map((l, i) => ({ value: l, label: l })),
            selectedSite: { value: selectedSite, label: selectedSite },
            selectedClass: { value: selectedClass, label: selectedClass },
            selectedFacility: { value: selectedFacility, label:  selectedFacility },
            selectedLevel: { value: selectedLevel, label: selectedLevel },
            dsLoaded:true
          })

        })
      }
    });
  }

  getDates(ds){
    cancel();
    this.setState({
      dateInfoText: 'Fetching available dates...',
      dateInfoTextColor: okColor,
      variableInfoText: 'Select a date to get available variables',
      variableInfoTextColor: okColor,
      variables: [],
      selectedVariable: '',
      coordVars: [],
      selectedCoordVar: '',
      has2D: false,
      genPlotsIsDisabled: true
    })

    axios.post(this.props.apiBaseURL + 'get_dates_celery', {
      "ds":ds
    }, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      if(typeof(res) !== "undefined") {
      let result = res.data
      let task_id = result.task_id

      this.props.checkTaskStatus(task_id, (result) => {

        let momentDates = []
        for(let i=0;i<result.dates.length;i++){
          momentDates.push(moment(result.dates[i]).startOf('day'))
        }

        let startDate = moment(result.sdate)
        let endDate   = moment(result.edate)

        let plotStartDate = endDate  
        let plotEndDate   = endDate

        if(this.props.hasQueryString) {
          // Handle sdate calculations
          if(this.props.queryStringObj.sdate.includes("$latest")) {

            if(this.props.queryStringObj.sdate.includes("-")) {
              let sdate_parts = this.props.queryStringObj.sdate.split('-')
              plotStartDate = moment(plotStartDate.format('YYYY-MM-DD')).subtract(parseInt(sdate_parts[1]),'days')
            } 
          }
          else {
            plotStartDate = moment(this.props.queryStringObj.sdate)
          }

          // Handle edate calulations
          if(this.props.queryStringObj.edate.includes("$latest")) {

            if(this.props.queryStringObj.edate.includes("-")) {
              let edate_parts = this.props.queryStringObj.edate.split('-')
              plotEndDate = moment(plotEndDate.format('YYYY-MM-DD')).subtract(parseInt(edate_parts[1]),'days')
            }
          }
          else {
            plotEndDate = moment(this.props.queryStringObj.edate)
          }
        }

        if (this.props.plotIsActive) {

            if (this.state.startDate > moment(endDate.format('YYYY-MM-DD')) || this.state.endDate < moment(startDate.format('YYYY-MM-DD'))) {
                this.setState({
                  dateInfoText: "No data available for " + this.state.selectedDatastream.value + " during the selected date range.",
                  dateInfoTextColor:  badColor,
                })
            }
            else {
                this.setState({
                  dateInfoText: "Data available from " + moment(result.sdate).format('YYYY-MM-DD') + ' to ' + moment(result.edate).format('YYYY-MM-DD'),
                  dateInfoTextColor:  goodColor,
                })
                this.getVariables(ds, moment(plotEndDate.format('YYYY-MM-DD')).format('YYYY/MM/DD'))
            }
        }
        else {
            this.setState({
              startDate: moment(plotStartDate.format('YYYY-MM-DD')),
              endDate: moment(plotEndDate.format('YYYY-MM-DD')),
              dates: momentDates,
              dateInfoText: "Data available from " + moment(result.sdate).format('YYYY-MM-DD') + ' to ' + moment(result.edate).format('YYYY-MM-DD'),
              dateInfoTextColor:  goodColor,
            })
            this.getVariables(ds, moment(plotEndDate.format('YYYY-MM-DD')).format('YYYY/MM/DD'))
        }
      })
    }}).catch(thrown => {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled');
        return
      } else {
        // handle error
      }
    })
    
  }

  getVariables(ds, date){
    this.setState({
      variableInfoText: 'Fetching available variables...',
      variableInfoTextColor:  okColor,
    })
    
    axios.post(this.props.apiBaseURL + 'get_variables_celery', {
      "ds":ds,
      "sdate":date
    }, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      if(typeof(res) !== "undefined") {
      let result = res.data
      let task_id = result.task_id

      this.props.checkTaskStatus(task_id, (result) => {

        if('error' in result) {
          this.setState({
            variableInfoText: 'Error fetching variables.',
            variableInfoTextColor:  badColor,
          },() => {
            alert("ERROR: " + result['error'])
          })
          return
        }

        let tempVars = []
        let coordVarData = {}
        let queryStringVarIs2D = false

        for(let i=0; i<result.variables.length;i++){
          if(result.variable_dims.num_dims[i] > 1){
            if(this.props.hasQueryString && result.variables[i] == this.props.queryStringObj.variable){
              queryStringVarIs2D = true
            }
            tempVars.push({ value: result.variables[i], label: result.variables[i] + ' (2D)' })
            coordVarData[result.variables[i]] = {"coord_data":result.coord_data[result.variable_dims.coord_var[i]].data, "coord_label":result.coord_data[result.variable_dims.coord_var[i]].label.toUpperCase(), "coord_dim": result.variable_dims.coord_var[i]}
          }
          else{
            tempVars.push({ value: result.variables[i], label: result.variables[i] })
          }
        }

        tempVars.sort(function(a, b) {
          var nameA = a.label.toUpperCase(); // ignore upper and lowercase
          var nameB = b.label.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        let tempSelectedVar = tempVars[0];

        if (this.props.hasQueryString){
          if (result.variables.includes(this.props.queryStringObj.variable)){
            if (queryStringVarIs2D){
              tempSelectedVar = { value: this.props.queryStringObj.variable, label: this.props.queryStringObj.variable + ' (2D)'}
            }
            else{
              tempSelectedVar = { value: this.props.queryStringObj.variable, label: this.props.queryStringObj.variable}
            }
          }
          else {
            // this.props.cancelPlotLoad()
            alert('Requested variable not found. Defaulting to first available variable in data file: ' + tempSelectedVar.value)
          }
          
        }

        this.setState({
          variableInfoText: result.variables.length + ' available variables',
          variableInfoTextColor:  goodColor,
          variables: tempVars,
          selectedVariable: tempSelectedVar,
          genPlotsIsDisabled: false,
          coordVarData: coordVarData
        }, () => {
          this.handleVariableChange(tempSelectedVar)
        })
      })
    }}).catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled');
        return
      } else {
        // handle error
      }
    });
  }

  decomposeDatastream(datastream){
    let site = ''
    let instrument = ''
    let facility = ''
    let data_level = ''

    if(datastream!==''){
      let dot_index = datastream.indexOf(".");
      let instrument_and_facility = datastream.slice(3, dot_index);

      site = datastream.slice(0,3).toUpperCase();
      data_level = datastream.slice(dot_index + 1);
      let facility_index = instrument_and_facility.search(/[A-Z]+[0-9]+/);
      instrument = instrument_and_facility.slice(0, facility_index);
      facility = instrument_and_facility.slice(facility_index);
    }

    return {"site": site, "instrument": instrument, "facility": facility, "data_level":data_level};
  }


  genPlots(callback=null){

    var shouldPlot = true

    // If not loading from query string, ask user if they want to continue
    // if number of days being plotted is > 30
    if(!this.props.hasQueryString) {
      let delta = moment(this.state.endDate).diff(moment(this.state.startDate), 'days')
      if(delta > 30) {
        shouldPlot = window.confirm("Are you sure you want to plot " + delta.toString() + " days worth of data? Plotting too much data at once can result in excessive load times and/or plotting issues.");
      }
      else{
        shouldPlot = true
      }
    }

    let delta = moment(this.state.endDate).diff(moment(this.state.startDate), 'days')

   


    if (shouldPlot) {
      let drop_variables = []
      this.state.variables.forEach((v) => {
        if (v.value != this.state.selectedVariable.value && v.value != 'qc_'+this.state.selectedVariable.value && 'qc_'+v.value != this.state.selectedVariable.value){
          drop_variables.push(v.value)
        }
      })

      let reqData = {
        'ds':this.state.selectedDatastream.value,
        'sdate':moment(this.state.startDate).format('yyyy-MM-DD'),
        'edate':moment(this.state.endDate).format('YYYY-MM-DD'),
        'variable': this.state.selectedVariable.value,
        'drop_variables': drop_variables,
        'coordinate': this.state.has2D ? String(this.state.selectedCoordVar.value) : '',
        'qc_check': this.state.qcEnabled && this.state.hasQC ? this.state.qcSelection : '',
        'coord_dim': this.state.has2D ? this.state.coordVarDim : '',
      }

      this.props.generatePlot(reqData, this.state.has2D && this.state.selectedCoordVar.value === 'all', cancel, false, () => {
        if (callback != null) {
          callback()
        }
      })
    }
  }

  addPlot(){

    let drop_variables = []
    this.state.variables.forEach((v) => {
      if (v.value != this.state.selectedVariable.value && v.value != 'qc_'+this.state.selectedVariable.value && 'qc_'+v.value != this.state.selectedVariable.value){
        drop_variables.push(v.value)
      }
    })

    let reqData = {
      'ds':this.state.selectedDatastream.value,
      'sdate':moment(this.state.startDate).format('YYYY-MM-DD'),
      'edate':moment(this.state.endDate).format('YYYY-MM-DD'),
      'variable': this.state.selectedVariable.value,
      'drop_variables': drop_variables,
      'coordinate': this.state.has2D ? String(this.state.selectedCoordVar.value) : '',
      'qc_check': this.state.qcEnabled && this.state.hasQC ? this.state.qcSelection : '',
      'coord_dim': this.state.has2D ? this.state.coordVarDim : '',
    }
    this.props.generatePlot(reqData, this.state.has2D && this.state.selectedCoordVar.value === 'all', cancel, true)
  }


  loadOptions = inputValue =>
    new Promise(resolve => {
      if(inputValue !== ''){
        setTimeout(() => {
        resolve(this.state.datastreams.filter(i => i.label.toLowerCase().startsWith(inputValue.toLowerCase())));
        }, 1000);
      }
      else{
         resolve([])
      }

  });

  renderThumbVertical = ({ style, ...props }) => {
    return <div {...props} style={{ ...style, backgroundColor: 'white', opacity: '0.6', borderRadius:3}}/>
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state.selectedDatastream.value !== prevState.selectedDatastream.value && typeof this.state.selectedDatastream.value !== 'undefined'){
      this.getDates(this.state.selectedDatastream.value)
    }
  }



  render(){
    return( <div tabIndex="0" style={{height:'100%'}}>
        <div className='menu-header'>
          <span><img className='menu-header-logo' src={logo}/></span>
          <p className='menu-header-text'>DQ-Zoom Plotter</p>

          <div className='menu-login-div'>
            {this.props.currentUser == false ?
              <div>
               <Tooltip
                  title="Sign in with ARM SSO to save <br>plot views to your favorites."
                  position='right'
                  distance={5}>
                  <FontAwesomeIcon style={{display:'inline-block'}} size="sm" icon={faInfoCircle} />
                </Tooltip>
                <p style={{textAlign: 'center', paddingBottom: 5, paddingLeft: 10, display:'inline-block'}}>Welcome, Guest</p>
                <button onClick={this.props.toggleAuthModal} className='plot-menu-button'>Sign in with ARM SSO</button>
              </div>
              :
              <div>
                <p style={{textAlign: 'center', paddingBottom: 5, paddingLeft: 10, display:'inline-block'}}>Welcome, {this.props.currentUser.name}</p>
                <button onClick={this.props.displayUserFavoritesWindow} className='plot-menu-button'>View Favorites</button>
                <button onClick={this.props.logOutUser} style={{marginTop:5, backgroundColor: "#c35b5b"}} className='plot-menu-button'>Log Out</button>
              </div>
            }
            
          </div>
          <div style={{height:2, width: "100%", backgroundColor: "#162c53", marginTop:10, marginBottom:10}} />

        </div>
        <div className='menu-options-scroll'>
          <Scrollbars style={{ width: '100%', height: '100%'}}  renderThumbVertical={this.renderThumbVertical}>
            <div className='menu-options-inner'>  

              <p className='menu-options-label'>DATASTREAM</p>
              <AsyncSelect
                isDisabled={!this.state.dsLoaded}
                cacheOptions
                styles={customSelectStyles}
                loadOptions={this.loadOptions}
                noOptionsMessage={() => 'Start typing to see options.'}
                onChange={this.handleDsChange}
                value={this.state.selectedDatastream}
              />
              <p className='menu-options-info' style={this.state.dsLoaded ? {color:goodColor} : {color:okColor}}> {this.state.dsLoaded ? this.state.datastreams.length + ' available datastreams': 'Fetching datastream options...'} </p>
              <br/>
              <p className='menu-options-label'>SITE</p>
              <Select
                isDisabled={!this.state.sites.length > 0}
                options={this.state.sites}
                styles={customSelectStyles}
                onChange={this.handleSiteChange}
                value={this.state.selectedSite}
              />
              <p className='menu-options-label'>DATASTREAM CLASS</p>
              <Select
                isDisabled={!this.state.classes.length > 0}
                options={this.state.classes}
                styles={customSelectStyles}
                onChange={this.handleClassChange}
                value={this.state.selectedClass}
              />
              <p className='menu-options-label'>FACILITY</p>
              <Select
                isDisabled={!this.state.facilities.length > 0}
                options={this.state.facilities}
                styles={customSelectStyles}
                onChange={this.handleFacilityChange}
                value={this.state.selectedFacility}
              />
              <p className='menu-options-label'>LEVEL</p>
              <Select
                isDisabled={!this.state.levels.length > 0}
                options={this.state.levels}
                styles={customSelectStyles}
                onChange={this.handleLevelChange}
                value={this.state.selectedLevel}
              />
              <br/>
              <DateRange
                dateChange = {this.handleDateChange}
                dateSwitch = {this.handleDateSwitch}
                startDate = {this.state.startDate}
                endDate = {this.state.endDate}
                dates = {this.state.dates}
                plotIsActive = {this.props.plotIsActive}
              />
              <p className='menu-options-info' style={{ color:this.state.dateInfoTextColor}}>{this.state.dateInfoText}</p>
              <br/>
              <p className='menu-options-label'>VARIABLE</p>
              <Select
                isDisabled={!this.state.variables.length > 0}
                options={this.state.variables}
                styles={customSelectStyles}
                onChange={this.handleVariableChange}
                value={this.state.selectedVariable}
              />
              <p className='menu-options-info' style={{color:this.state.variableInfoTextColor}}>{this.state.variableInfoText}</p>
              {this.state.has2D 
              ? 
                <div>
                  <p className='menu-options-label'>{this.state.coordVarLabel}</p>
                  <Select
                    isDisabled={!this.state.coordVars.length > 0}
                    options={this.state.coordVars}
                    styles={customSelectStyles}
                    onChange={this.handleCoordVarChange}
                    value={this.state.selectedCoordVar}
                  />
                </div>
                :
                null
              }

              {this.state.hasQC & !this.state.has2D 
              ?
                <div>
                  <br/>
                  <p className='menu-options-label'>Embed QC:</p>
                  <label>
                    <div style={{float:'right'}}>
                      <Toggle
                        className='custom-toggle'
                        icons={false}
                        onChange={this.handleQCToggle} 
                        checked={this.state.qcEnabled}
                      />
                    </div>
                  </label>

                  <br/>

                  {this.state.qcEnabled
                  ?
                  <QCOptions
                    handleQCRadioChange={this.handleQCRadioChange}
                    qcSelection={this.state.qcSelection}
                  />
                  :
                  null}
                </div>
              :
              null}

              <GenPlotButton
                disabled = {this.state.genPlotsIsDisabled}
                onClick = {this.genPlots}
              />

              {this.props.plotIsActive ?
                <AddPlotButton
                  disabled = {this.state.genPlotsIsDisabled}
                  onClick = {this.addPlot}
                />
                // null
              :
                null
              }

              {this.props.plotIsActive ?
              <div>
                <p className='menu-options-label'>TIME LOGGING</p>
                <label>
                  <div style={{float:'right'}}>
                    <Toggle
                      className='custom-toggle'
                      checked={this.props.timeLoggingIsActive}
                      icons={false}
                      onChange={this.props.handleTimeLoggingToggle}
                    />
                  </div>
                </label>
                {this.props.timeLoggingIsActive 
                ? 
                  <div>
                    <p style={{color:goodColor}} className='menu-options-info'>Select {this.props.timeLoggingStart ? 'start' : 'end'} date for logging</p> 
                    <br/>
                  </div>
                : 
                  <div>
                    <br/>
                  </div>
                }

                <p className='menu-options-label'>Y-AXIS LOG SCALE</p>
                <label>
                  <div style={{float:'right'}}>
                    <Toggle
                      className='custom-toggle'
                      defaultChecked={false}
                      icons={false}
                      onChange={this.props.handleLogScaleToggle} 
                    />
                  </div>
                </label>

                {this.props.plotIs2D
                ?
                  <div>
                    <br/>
                    <p className='menu-options-label'>CUSTOM Z-AXIS RANGE</p>
                    <label>
                      <div style={{float:'right'}}>
                        <Toggle
                          className='custom-toggle'
                          defaultChecked={false}
                          icons={false}
                          onChange={this.props.handleCustomZRangeToggle} 
                        />
                      </div>
                    </label>

                    {this.props.customZRange
                    ?
                      <div>
                        <br/>
                        <div className="custom-z-range-container">
                            <div style={{width: "45%", float: "left"}}>
                                <p style={{margin:0, fontWeight: "bold", color:"#FFFFFF", textTransform: "uppercase", fontSize: 12}}>Min Z:</p>
                                <input defaultValue={this.props.dataStats.min} onChange={(e) => this.props.handleZRangeChange("min", e)} autoComplete="off" className="date-input" style={{width: "100%", position: "relative"}} type="text" placeholder="Min Z:"/>
                            </div>
                            <div style={{width: "45%", float: "right"}}>
                                <p style={{margin:0, fontWeight: "bold", color:"#FFFFFF", textTransform: "uppercase", fontSize: 12}}>Max Z:</p>
                                <input defaultValue={this.props.dataStats.max} onChange={(e) => this.props.handleZRangeChange("max", e)} autoComplete="off" className="date-input" style={{width: "100%", position: "relative"}} type="text" placeholder="Max Z:"/>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                      </div>
                    :
                        null
                    }
                  </div>
                :
                  null
                }
                 

                <br/>
                <br/>
                <Tooltip
                  title="Generate link to share plot."
                  position='top'
                  distance={15}>
                  <ShareButton shareLink={this.props.shareLink}/>
                </Tooltip>
                {
                  this.props.currentUser !== false ?
                  <Tooltip
                  title="Save current plot to your favorites list."
                  position='top'
                  distance={15}>
                    <FavButton isAddingToFavorites={this.props.isAddingToFavorites} addUserFavorite={this.props.addUserFavorite} shareLink={this.props.shareLink}/>
                  </Tooltip>
                    
                  :
                    null
                }
               
                <br/>
                <br/>

                <ClearPlotButton
                    disabled = {false}
                    shouldRefreshDates = {this.state.variables.length == 0}
                    onClick = {this.props.clearPlot}
                    selectedDatastream = {this.state.selectedDatastream}
                    getDates = {this.getDates}
                />
                
              </div>
              :
              null
            }

            </div>
          </Scrollbars>
        </div>
      </div>
    );
  }
}


class DateRange extends React.Component {

  isPlotAvailableForDate = (caldate) => {

    for(let date of this.props.dates) {
      if(caldate.isSame(date)){
        return true
      }
    }

    return false
  }

  handleChangeStart = (startDate) => this.props.dateChange({ startDate })
  handleChangeEnd = (endDate) => this.props.dateChange({ endDate })

  render () {
    return <div>
      <div className='sdate-div'>
        <p className='menu-options-label'>START DATE</p>
        <DatePicker
          withPortal
          filterDate={this.isPlotAvailableForDate}
          selected={this.props.startDate}
          selectsStart
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          // minDate={this.props.dates[0]}
          // maxDate={this.props.dates[this.props.dates.length - 1]}
          //includeDates={this.props.dates}
          onChange={this.handleChangeStart}
          className="date-input"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="YYYY-MM-DD"
          disabled={this.props.plotIsActive}
        />
      </div>
      <div className='date-switch-div'>
        <div onClick={()=>this.props.dateSwitch('starttoend')} className="start_to_end_button match_button"><FontAwesomeIcon size="2x" icon="long-arrow-alt-right" /></div>
        <div onClick={()=>this.props.dateSwitch('endtostart')} className="end_to_start_button match_button"><FontAwesomeIcon size="2x" icon="long-arrow-alt-left" /></div>
      </div>
      <div className='edate-div'>
        <p className='menu-options-label'>END DATE</p>
        <DatePicker
         withPortal
          filterDate={this.isPlotAvailableForDate}
          selected={this.props.endDate}
          selectsEnd
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          // minDate={this.props.dates[0]}
          // maxDate={this.props.dates[this.props.dates.length -1]}
          //includeDates={this.props.dates}
          onChange={this.handleChangeEnd} 
          className="date-input"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="YYYY-MM-DD"
          disabled={this.props.plotIsActive}
        />
      </div>
    </div>
  }
}

class QCOptions extends Component {

  render() {
    return(
      <div style={{marginTop:5}}>
        <p className='menu-options-label'><strong>ALL:</strong></p>
        <div className='qc-div'>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="all-overlay" type="radio" checked={this.props.qcSelection == 'all-overlay'}/>
          <label style={{paddingRight:10}} htmlFor="all-overlay">Overlay</label>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="all-remove" type="radio" checked={this.props.qcSelection == 'all-remove'}/>
          <label htmlFor="all-remove">Remove</label>
        </div>
        <br/>
        <p className='menu-options-label'><strong>BAD:</strong></p>
         <div className='qc-div'>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="bad-overlay" type="radio" checked={this.props.qcSelection == 'bad-overlay'}/>
          <label style={{paddingRight:10}} htmlFor="bad-overlay">Overlay</label>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="bad-remove" type="radio" checked={this.props.qcSelection == 'bad-remove'}/>
          <label htmlFor="bad-remove">Remove</label>
        </div>
        <br/>
        <p className='menu-options-label'><strong>IND:</strong></p>
         <div className='qc-div'>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="ind-overlay" type="radio" checked={this.props.qcSelection == 'ind-overlay'}/>
          <label style={{paddingRight:10}} htmlFor="ind-overlay">Overlay</label>
          <input onChange={this.props.handleQCRadioChange} autoComplete="off" value="ind-remove" type="radio" checked={this.props.qcSelection == 'ind-remove'}/>
          <label htmlFor="ind-remove">Remove</label>
        </div>
      </div>
    );
  }
}

class GenPlotButton extends Component {

  render(){
    return <div className='plot-menu-button-div'>
      <button disabled={this.props.disabled} onClick={() => {this.props.onClick(null)}} className='plot-menu-button'>Generate Plot</button>
    </div>
  }
}

class AddPlotButton extends Component {
  render() {
    return(
      <div style={{paddingTop:0}} className='plot-menu-button-div'>
        <button disabled={this.props.disabled} onClick={this.props.onClick} className='plot-menu-button'>Add to Plot</button>
      </div>
    );
  }
}

class ClearPlotButton extends Component {
  onClearPlot = () => {
    this.props.onClick()
    if(this.props.shouldRefreshDates){
        this.props.getDates(this.props.selectedDatastream.value)
    }
    
  }
  render() {
    return(
      <div style={{paddingTop:0}} className='plot-menu-button-div'>
        <button disabled={this.props.disabled} onClick={this.onClearPlot} className='plot-menu-button clear-button'>Clear Plot</button>
      </div>
    );
  }
}

class FavButton extends Component {
  constructor(props){
    super(props)

    this.state = {
      isShowingModal: false,
      selectedDateOption: "static",
      numDays: 7,
      infoMsg: '',
      infoMsgColor: '#019257'
    }

  }

  handleClick = () => {
    this.setState({isShowingModal: true})
  }

  handleClose = () => {
    this.setState({isShowingModal: false, infoMsgColor: '#019257', infoMsg: ''})
  }

  parseQueryString = (query_str) => {
    let queryStringObj = qs.parse(query_str)
    let queryStringIsValid = false

    try{
      queryStringIsValid = queryStringObj.ds.length>0 && queryStringObj.variable.length>0 && queryStringObj.sdate.length>0 && queryStringObj.edate.length>0;
    }
    catch(e){
      console.log(e)
    }

    let ds = ''
    let variable = ''
    let sdate = ''
    let edate = ''
    let coordinate = ''
    let add_variables = ''

    if(queryStringIsValid) {
      ds = queryStringObj.ds
      variable = queryStringObj.variable
      sdate = queryStringObj.sdate
      edate = queryStringObj.edate

      if ('coordinate' in queryStringObj) {
        coordinate = queryStringObj.coordinate
      }

      if ('addvariables' in queryStringObj) {
        add_variables = queryStringObj.addvariables
      }
    }

    return {
      'ds': ds,
      'variable': variable,
      'sdate': sdate,
      'edate': edate,
      'coordinate': coordinate,
      'add_variables': add_variables
    }
  }


  handleAddFavorite = (ds, variable, sdate, edate, coordinate, add_variables) => {
    this.setState({'infoMsg': "Adding to favorites..."})
    this.props.addUserFavorite(ds, variable, coordinate, sdate, edate, add_variables, (result) => {
      if('error' in result) {
        this.setState({'infoMsg': result.error, 'infoMsgColor': '#cc4444'})
      }
      else{
        this.setState({'infoMsg': "Favorite added successfully", 'infoMsgColor': '#019257'})
      }
    })
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedDateOption: changeEvent.target.value
    });
  };

  handleNumDaysOptionChange = changeEvent => {
    this.setState({
      numDays: changeEvent.target.value
    });
  };


  render(){
    let qs_obj = this.parseQueryString(this.props.shareLink.split('?')[1])

    return(
      <div style={{display:"inline-block", paddingLeft:10}}>
        <FontAwesomeIcon className='share' onClick={this.handleClick} size="2x" icon={faHeart} onClick={this.handleClick}/>
         <Modal open={this.state.isShowingModal} onClose={this.handleClose} classNames={{ overlay: 'share-overlay', modal: 'share-modal', closeButton:'share-close-button', closeIcon:'share-close-icon'}} center>
          <div style={{lineHeight:'10px'}} className="buttonInside">
            <p><b>Datastream:</b> {qs_obj.ds}</p>
            <p><b>Variable:</b> {qs_obj.variable}</p>

            <input onChange={this.handleOptionChange} checked={this.state.selectedDateOption === "static"} type="radio" id="static" name="date_type" value="static"/>
            <label htmlFor="static">Static Date Range</label>
            <input onChange={this.handleOptionChange} checked={this.state.selectedDateOption === "dynamic"} type="radio" id="dynamic" name="date_type" value="dynamic"/>
            <label htmlFor="dynamic">Dynamic Date Range</label>

            <br/>
            <br/>
            { this.state.selectedDateOption === "static" ?
              <p><b>Date Range:</b> {qs_obj.sdate} - {qs_obj.edate}</p>
            :
              <p><b>Date Range:</b> Latest <input type="number" id="quantity" name="quantity" min="1" max="60" value={this.state.numDays} onChange={this.handleNumDaysOptionChange}/> days. (based on data availability)</p>
            }

            { qs_obj.coordinate !== "" ?
              <p><b>Coordinate Variable:</b> {qs_obj.coordinate}</p>
            :
              null
            }
            { qs_obj.add_variables !== "" ? (
              <div>
                <p><b>Comparison Datastreams:</b></p>

                {qs_obj.add_variables.replace(/[\[\]']+/g,'').split(",").map((addVar, index) => (
                   <p key={index.toString()+addVar.toString()}>{addVar}</p>
                ))}
              </div>
            )
            :
              null
            }

            <button disabled={this.props.isAddingToFavorites} onClick={() => {
              let sdate = qs_obj.sdate
              let edate = qs_obj.edate

              if (this.state.selectedDateOption === "dynamic"){
                sdate = "$latest-"+this.state.numDays 
                edate = "$latest"
              }

              this.handleAddFavorite(qs_obj.ds, qs_obj.variable, sdate, edate, qs_obj.coordinate, qs_obj.add_variables)
            }} className='plot-menu-button'>Save to Favorites</button>
            <p style={{color: this.state.infoMsgColor}}>{this.state.infoMsg}</p>
          </div>
        </Modal>
      </div>
    )
  }
}

class ShareButton extends Component {
  constructor(props){
    super(props)

    this.state = {
      isShowingModal: false,
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(){
    this.setState({isShowingModal: true})
  }

  handleClose(){
    this.setState({isShowingModal: false})
  }

  render(){
    return <div style={{display:"inline-block"}}>
      <FontAwesomeIcon className='share' onClick={this.handleClick} size="2x" icon="share-alt" />
        <Modal open={this.state.isShowingModal} onClose={this.handleClose} classNames={{ overlay: 'share-overlay', modal: 'share-modal', closeButton:'share-close-button', closeIcon:'share-close-icon'}} center>
          <h3>Share Link</h3>
          <div className="buttonInside">
            <input readOnly value={this.props.shareLink} className='share-link'/>


              <Clipboard data-clipboard-text={this.props.shareLink} className='share-link-button'>
                <Tooltip
                title="Link Copied"
                position="bottom"
                trigger="click"
                arrow="true"
                duration='50'
                >
                  <FontAwesomeIcon size="lg" icon="clipboard" />
                </Tooltip>
              </Clipboard>
            
          </div>
        </Modal>
    </div>
  }
}