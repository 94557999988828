import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './registerServiceWorker';

import { BrowserRouter, Route, Routes } from 'react-router-dom'

// ReactDOM.render((
//   <BrowserRouter>
//     <Routes>
//       <Route exact path="/" element={<App/>}/> 
//     </Routes>
//   </BrowserRouter>
// ), document.getElementById('root'))

ReactDOM.render(<App />, document.getElementById('root'));
unregister()
// registerServiceWorker();


