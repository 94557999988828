import React, { Component } from 'react';
import ReactDOM from "react-dom";

import NewWindow from 'react-new-window';
import Select from 'react-select';

import '../App.css';
import './UserFavoritesWindow.css';

export default class UserFavoritesWindow extends Component {

  render() {

    let displayData = {}

    for (let i in this.props.user['favorites']) {
      let currFav = this.props.user['favorites'][i]

      if (this.props.userFavoritesSortOption == "site") {
        let site = currFav['datastream'].substring(0,3);
        if (!(site in displayData)) {
          displayData[site] = []
        }
        displayData[site].push(currFav)
      }
      else {
        let datastream = currFav['datastream'].substring(3,currFav['datastream'].length-1).split(/(?=[A-Z])/)[0]
         if (!(datastream in displayData)) {
          displayData[datastream] = []
        }
        displayData[datastream].push(currFav)
      }  
    }


    return(
      this.props.isOpen && this.props.user !== false
      ?
      <NewWindow features={{width: 900, height:600}} onUnload={this.props.onClose} copyStyles={true} title='DQ-Zoom Favorites' name='favorites_window'>
        <div style={{paddingLeft:20}}>
          <p style={{display: 'inline-block'}}><b>Sort By:</b></p>
          <select style={{marginLeft:10, display: 'inline-block'}} onChange={this.props.handleUserFavoritesSortChange} value={this.props.userFavoritesSortOption}>
            <option value="site">Site</option>
            <option value="class">Datastream Class</option>
          </select>
          
        </div>
          { !this.props.isFetchingFavorites ?

            (Object.keys(displayData).map((currSite, index) => (
                <div  key={currSite+"-div"} style={{whiteSpace: 'pre-wrap', padding: 20}}>
                  <h1 key={currSite}>{currSite.toUpperCase()}</h1>
                  {
                    displayData[currSite].map((currFav, index) => (
                      <FavoriteEntry key={currFav.datastream+index.toString()} deleteUserFavorite={this.props.deleteUserFavorite} favObj={currFav}/>
                    ))
                  }
                </div>
            )))
            :
            <div style={{height:'100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h3>Fetching favorites...</h3>
            </div>

          }
      </NewWindow>
      :
      null
    )
  }

}

class FavoriteEntry extends Component {

  genShareLink = () => {
    console.log(this.props)
    let tempURL = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' 
    tempURL = tempURL + 'ds=' + this.props.favObj.datastream + '&variable=' + this.props.favObj.variable + '&sdate=' + this.props.favObj.sdate + '&edate=' + this.props.favObj.edate
    if('coordinate' in this.props.favObj && this.props.favObj.coordinate != ''){
      tempURL = tempURL + '&coordinate=' + this.props.favObj.coordinate 
    }
    if('addvariables' in this.props.favObj && this.props.favObj.addvariables != ''){
      tempURL = tempURL + '&addvariables=' + this.props.favObj.addvariables 
    }

    return tempURL
  }

  handleDeleteFavorite = () => {
    this.props.deleteUserFavorite(this.props.favObj.datastream, this.props.favObj.variable, this.props.favObj.coordinate, this.props.favObj.sdate, this.props.favObj.edate, this.props.favObj.addvariables, () => {
      
    })
  }

  render () {
    return(
      <div className='favorite-entry-container'>
        <div style={{float:"left"}}>
          <p><b>Datastream:</b> {this.props.favObj.datastream}</p>
          <p><b>Variable:</b> {this.props.favObj.variable}</p>
          { this.props.favObj.sdate.includes("$latest") ?
            <p><b>Date Range (dynamic):</b> Latest {this.props.favObj.sdate.split("-")[1]} days. (based on data availability)</p>
            :
            <p><b>Date Range (static):</b> {this.props.favObj.sdate} - {this.props.favObj.edate}</p>
          }
          { this.props.favObj.coordinate != '' ?
            <p><b>Coordinate:</b> {this.props.favObj.coordinate}</p>
          :
            <p><b>Coordinate:</b> N/A</p>
          }
          { this.props.favObj.addvariables != '' ?
            <p><b>Comparison Datastreams:</b> {this.props.favObj.addvariables}</p>
          :
            <p><b>Comparison Datastreams:</b> N/A</p>
          }
          
          
        </div>
        <div className='favorite-entry-buttons-container'>
          <a href={this.genShareLink()} target="_blank" rel="noopener noreferrer"><button className="plot-menu-button">Generate Plot</button></a>
          <button onClick={this.handleDeleteFavorite} style={{marginTop: 10, backgroundColor:'#c35b5b'}} className="plot-menu-button">Delete Favorite</button>
        </div>
      </div>
    )
  }
}