import React, { Component } from 'react';
import ReactDOM from "react-dom";

import axios from 'axios';
import * as math from 'mathjs';
import Menu from 'react-burger-menu/lib/menus/push';
import moment from 'moment';
import jwt_decode from "jwt-decode";

import './App.css';

import PlotSelectMenu from "./components/SideMenu";
import InteractivePlot from "./components/InteractivePlot";
import HelpButton from "./components/HelpButton";
import LoggingWindow from "./components/TimeLoggingWindow";
import FileHeaderWindow from "./components/FileHeaderWindow";
import UserFavoritesWindow from "./components/UserFavoritesWindow";
import LoginModal from "./components/LoginModal";
import { WarningBanner } from './components/WarningBanner'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard, faExclamationCircle
 } from '@fortawesome/free-solid-svg-icons'
library.add(faLongArrowAltRight, faLongArrowAltLeft, faSpinner, faShareAlt, faClipboard, faExclamationCircle)

var qs = require('qs');
var nj = require('numjs');
var d3 = require('d3');


// Extend the d3 library to allow us to move SVG elements to the top
// This is needed to make DQR links in legend clickable
d3.selection.prototype.moveToFront = function() {  
  return this.each(function(){
    this.parentNode.appendChild(this);
  });
};


const max_size = 1000000;
const CancelToken = axios.CancelToken;
let cancel;


class App extends Component {

  constructor(props) {
    super(props)

    let queryStringObj = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    let hasQueryString = false

    console.log(queryStringObj, hasQueryString)

    try{
      hasQueryString = queryStringObj.ds.length>0 && queryStringObj.variable.length>0 && queryStringObj.sdate.length>0 && queryStringObj.edate.length>0;
    }
    catch(e){}

    console.log(queryStringObj, hasQueryString)

    this.handleMenuChange = this.handleMenuChange.bind(this);
    this.generatePlot = this.generatePlot.bind(this);
    this.checkNull = this.checkNull.bind(this);
    this.checkBad = this.checkBad.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.formatTitleDate = this.formatTitleDate.bind(this)
    this.closest = this.closest.bind(this)
    this.createHist = this.createHist.bind(this)
    this.getStats = this.getStats.bind(this)
    this.displayFileHeaderWindow = this.displayFileHeaderWindow.bind(this)
    this.onFileHeaderWindowClose = this.onFileHeaderWindowClose.bind(this)
    this.handlePlotChange = this.handlePlotChange.bind(this)
    this.handlePlotClick = this. handlePlotClick.bind(this)
    this.handleTimeLoggingToggle = this.handleTimeLoggingToggle.bind(this)
    this.handleLogScaleToggle = this.handleLogScaleToggle.bind(this)
    this.deleteTimes = this.deleteTimes.bind(this)
    this.handleStartLoadFromQueryString = this.handleStartLoadFromQueryString.bind(this)
    this.clearPlot = this.clearPlot.bind(this)
    this.disableLoadFromQueryString = this.disableLoadFromQueryString.bind(this)

    this.state = {
      componentLoaded:false,
      apiBaseURL: '${APP_DQ_API_URL}/',
      allData: {},
      data_header:'',
      fileHeaderWindowIsOpen: false,
      plotData: [{x: [],y: [],type: 'scatter',mode: 'lines+points',marker: {color: 'red'}}],
      plotLayout: {datarevision:0, autosize: true,  margin: {l: 80,r: 80,b: 80,t: 40}},
      histData: [{x: [],y: [],type: 'histogram'}],
      histLayout: {autosize: true, margin: {l: 80,r: 0,b: 80,t: 10} },
      histLabel: '',
      dataStats: {
        min: null,
        max: null,
        mean: null,
        stdev: null,
      },
      menuIsOpen: true,
      plotIsActive: false, 
      plotIs2D: false,
      plotIsLoading: false,
      timeLoggingIsActive: false,
      timeLoggingWindowIsOpen: false,
      timeLoggingStart: true,
      timeLoggingPoints: [],
      yAxisLogScale: false,
      customZRange: false,
      shareLink: '',
      hasQueryString: hasQueryString,
      queryStringObj: queryStringObj,
      plotZoom: {
        minXIndex:0,
        maxXIndex:0,
        minYIndex:0,
        maxYIndex:0
      },
      currentUser: false,
      authenticationModalOpen: false,
      isAuthenticated: !!localStorage.token,
      isAuthenticating: false,
      authenticationMessage: '',
      userFavoritesWindowIsOpen: false,
      isAddingToFavorites: false,
      isFetchingFavorites: false,
      userFavoritesSortOption: 'site'
    }
  }

  componentDidMount(){
    this.getBaseURL()

    if (!!localStorage.token) {
      let decoded = jwt_decode(localStorage.token)
      this.setState({
        currentUser: {
          name: decoded.first_name + ' ' + decoded.last_name,
          id: decoded.id,
          favorites: []
        }
      }, ()=> {
        this.getUserFavorites()
      })
    }

  }

  getBaseURL(){
    let url = window.location.href;

    //Is DEV System
    if(url.indexOf("localhost") >= 0){
      this.setState({
        apiBaseURL: 'http://localhost:7100/',
        componentLoaded: true
      }, () => {
        console.log(this.state.componentLoaded)
      })
    }
    //Is Production System
    else{
      this.setState({
        componentLoaded: true
      })
    }
  }

  cancelPlotLoad = () => {
    this.setState({'hasQueryString':false, 'plotIsLoading':false})
  }

  disableLoadFromQueryString = () => {
    this.setState({'hasQueryString':false})
  }

  authenticateUser = (username, password) => {
    this.setState({
      isAuthenticating: true
    })
    let url = this.state.apiBaseURL + 'ldap_auth';

    let headers = new Headers();

    headers.set('Authorization', 'Basic ' + Buffer.from(username + ":" + password).toString('base64'));

    fetch(url, {method:'GET',
      headers: headers,
     })
    .then(response => response.json())
    .then(json => {
      if(json.result === 'success'){
        let decoded = jwt_decode(json.token)

        this.setState({
          isAuthenticated: true,
          isAuthenticating: true,
          authenticationModalOpen: false,
          authenticationMessage: "Authentication Successful",
          currentUser: {
            name: decoded.first_name + ' ' + decoded.last_name,
            id: decoded.id,
            favorites: []
          }
        }, () => {
          localStorage.setItem('token', json.token)
          this.getUserFavorites()
        })
      }
      else if (json.result === 'invalid_credentials'){
        this.setState({
          authenticationMessage: "Invalid Credentials",
          isAuthenticating: false
        })
      }
      else {
        this.setState({
          authenticationMessage: "Authentication Server Error",
          isAuthenticating: false
        })
      }
    });
  }

  handleUserFavoritesSortChange = (event) => {
    this.setState({
      userFavoritesSortOption: event.target.value
    })
  }

  logOutUser = () => {
    localStorage.removeItem('token')
    this.setState({
      isAuthenticated: false,
      isAuthenticating: false,
      authenticationModalOpen: false,
      authenticationMessage: "",
      currentUser: false
    })
  }

  deleteUserFavorite = (datastream, variable, coordinate, sdate, edate, add_variables, callback) => {
    let dummmyUser = this.state.currentUser
    for( let i in dummmyUser['favorites'] ) {
      let currFav = dummmyUser['favorites'][i]

      if (currFav.datastream == datastream && currFav.variable == variable && currFav.coordinate == coordinate && currFav.sdate == sdate && currFav.edate == edate && currFav.addvariables == add_variables) {
        dummmyUser['favorites'].splice(i, 1)
        break
      }
      
    }

    this.setState({'currentUser': dummmyUser})
    
     axios.post(this.state.apiBaseURL + 'delete_dqzoom_user_favorite_celery',{
      "user_id": this.state.currentUser.id,
      "datastream": datastream,
      "variable": variable,
      "coordinate":coordinate,
      "sdate":sdate,
      "edate":edate,
      "add_variables": add_variables
     },
     {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          callback({'error': 'Request canceled'})
        } else {
          // handle error
          callback({'error':thrown.message})
        }
      }).then(res => {

        if(typeof(res) !== "undefined") {
          let result = res.data
          let task_id = result.task_id

          this.checkTaskStatus(task_id, (result) => {
            if(!('error' in result)) {
              
            }

            callback(result)
          })
        }
      })
  }

  addUserFavorite = (datastream, variable, coordinate, sdate, edate, add_variables, callback) => {
    this.setState({isAddingToFavorites: true})
     axios.post(this.state.apiBaseURL + 'add_dqzoom_user_favorite_celery',{
      "user_id": this.state.currentUser.id,
      "datastream": datastream,
      "variable": variable,
      "coordinate":coordinate,
      "sdate":sdate,
      "edate":edate,
      "add_variables": add_variables
     },
     {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          this.setState({isAddingToFavorites: false})
          callback({'error': 'Request canceled'})
        } else {
          // handle error
          this.setState({isAddingToFavorites: false})
          callback({'error':thrown.message})
        }
      }).then(res => {


        if(typeof(res) !== "undefined") {
          let result = res.data
          let task_id = result.task_id

          this.checkTaskStatus(task_id, (result) => {
            if(!('error' in result)) {
              let dummmyUser = this.state.currentUser
              dummmyUser['favorites'].push(result)

              this.setState({'currentUser': dummmyUser})
            }

            this.setState({isAddingToFavorites: false})
            callback(result)
          })
        }
      })

  }

  getUserFavorites = () => {
    this.setState({
        isFetchingFavorites: true
    })
    let ogThis = this
     axios.post(this.state.apiBaseURL + 'get_dqzoom_user_favorites_celery',{
      "user_id": this.state.currentUser.id
     },
     {
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      }).catch(function(thrown) {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
        ogThis.setState({
            isFetchingFavorites: false
        })
        
      }).then(res => {

        if(typeof(res) !== "undefined") {
          let result = res.data
          let task_id = result.task_id

          this.checkTaskStatus(task_id, (result) => {
            let tempUser = this.state.currentUser
            tempUser.favorites = result
            this.setState({
              currentUser: tempUser,
              isFetchingFavorites: false
            })
          })
        }
      })

  }

  toggleAuthModal = () => {
    this.setState({
      authenticationModalOpen: !this.state.authenticationModalOpen
    })
  }


  checkTaskStatus = (task_id, callback) => {
    console.log(task_id)
    let query_data = {
      'task_id': task_id
    }
    let this_context = this
    axios.post(this.state.apiBaseURL + 'task_status', query_data, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      let data = res.data
      if (data['state'] == "FAILURE") {
        callback({'error': data['status']})
      }
      else if (data['state'] != 'PENDING' && data['status'] != 'Pending' && data['state'] != 'PROGRESS') {
          if ('result' in data) {
              callback(data['result'])
          }
          else {
              callback({})
          }
      }
      else {
          // rerun in 2 seconds
          setTimeout(function() {
              this_context.checkTaskStatus(task_id, callback);
          }, 500);
      }
    })
  }


  handleMenuChange(state) {
    this.setState({'menuIsOpen':state.isOpen}, () => {
      window.setTimeout(function(){
        window.dispatchEvent(new Event('resize'));
        // document.getElementsByClassName('modebar-btn')[4].click()
      }, 500)
    })
  };

  handleTimeLoggingToggle() { 
    this.setState({
      timeLoggingStart: true,
      timeLoggingIsActive: !this.state.timeLoggingIsActive,
      timeLoggingWindowIsOpen: false,
      timeLoggingPoints: []
    })
  }

  handleLogScaleToggle() {
    let tempLayout = this.state.plotLayout
    tempLayout['yaxis']['type'] = !this.state.yAxisLogScale ? 'log' : 'linear'
    tempLayout['yaxis']['autorange'] = true
    tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

    this.setState({
      yAxisLogScale: !this.state.yAxisLogScale,
      plotLayout: tempLayout
    }, () => {
      //document.getElementsByClassName('modebar-btn')[4].click()
    })
  }

  handleCustomZRangeToggle = () => {
    let tempPlotData = this.state.plotData
    let tempLayout = this.state.plotLayout

    tempPlotData[0]['zauto'] = this.state.customZRange
    tempPlotData[0]['zmin'] = this.state.dataStats.min
    tempPlotData[0]['zmax'] = this.state.dataStats.max

    tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

    this.setState({
      plotData: tempPlotData,
      customZRange: !this.state.customZRange,
      plotLayout: tempLayout
    })
  }

  handleZRangeChange = (type, e) => {
    // let tempLayout = this.state.plotLayout
    // tempLayout['zmin'] = 100
    // tempLayout['zmax'] = 100
    // tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

    let tempPlotData = this.state.plotData
    let tempLayout = this.state.plotLayout

    if(type === "min"){
      tempPlotData[0]['zmin'] = e.target.value
    }
    else if(type == "max"){
      tempPlotData[0]['zmax'] = e.target.value
    }

    tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

    this.setState({
      plotData: tempPlotData,
      plotLayout: tempLayout
    })
    

  }

  deleteTimes(times) {
    let tempPoints = this.state.timeLoggingPoints
    for(let i=0; i<tempPoints.length; i++){
      if(tempPoints[i][0].format('YYYY-MM-DD HH:mm:ss') == times[0] && tempPoints[i][1].format('YYYY-MM-DD HH:mm:ss') == times[1]){
        tempPoints.splice(i,1)
      }
    }
    this.setState({
      timeLoggingPoints: tempPoints,
    })
  }

  checkNull(val) {
    return val === null;
  }

  checkBad(val){
    if (val === -9999){
      return null;
    }
    else{
      return val;
    }
  }

  formatDate(dateobj){
    let tempYear = dateobj.getUTCFullYear()
    let tempMonth = dateobj.getUTCMonth() + 1
    let tempDay = dateobj.getUTCDate()
    let tempHour = dateobj.getUTCHours()
    let tempMinute = dateobj.getUTCMinutes()
    let tempSecond = dateobj.getUTCSeconds()


    if (tempMonth < 10) {
      tempMonth = "0" + tempMonth
    }
    if (tempDay < 10) {
      tempDay= "0" + tempDay
    }
    if (tempHour < 10) {
      tempHour = "0" + tempHour
    }
    if (tempMinute < 10) {
      tempMinute = "0" + tempMinute
    }
    if (tempSecond < 10) {
      tempSecond = "0" + tempSecond
    }

    return tempYear+'-'+tempMonth+'-'+tempDay+' '+tempHour+':'+tempMinute+':'+tempSecond
  }

  formatTitleDate(datestring){
    let tempYear = datestring.substring(0,4)
    let tempMonth = datestring.substring(5,7)
    let tempDay = datestring.substring(8,10)
    let tempHour = datestring.substring(11,13)
    let tempMinute = datestring.substring(14,16)
    let tempSecond = datestring.substring(17,19)

    let titleDate = tempYear+tempMonth+tempDay+'.'+tempHour+tempMinute+tempSecond

    return titleDate
  }

  closest (arr, num) {
    let curr = arr[0];
    let diff = Math.abs (num - curr);
    for (let val = 0; val < arr.length; val++) {
      let newdiff = Math.abs (num - arr[val]);
      if (newdiff < diff) {
        diff = newdiff;
        curr = val;
      }
    }
    return curr;
  }

  createHist(data, min, max){
    let hist_xlabel = this.state.histLabel
    let layout_h = 
    {
      margin: {
        l: 80,
        r: 40,
        b: 80,
        t: 10,
      },
      xaxis: {
        title: hist_xlabel,
        fixedrange: true
      },
      yaxis: {
        fixedrange: true
      },
    }
    let hbin=(max-min)/20.
    let hist_trace=[{
      x: data,
      name: 'histogram',
      type: "histogram",
      xbins: {
        end: max,
        size: hbin,
        start: min
      }
    }];

    this.setState({
      histData: hist_trace,
      histLayout: layout_h
    })
  }

  displayFileHeaderWindow() {
    this.setState({
      fileHeaderWindowIsOpen: true
    })
  }

  onFileHeaderWindowClose = () => {
     this.setState({
      fileHeaderWindowIsOpen: false
    })
  }

  displayUserFavoritesWindow = () => {
    this.setState({
      userFavoritesWindowIsOpen: true
    })
  }

  onUserFavoritesWindowClose = () => {
     this.setState({
      userFavoritesWindowIsOpen: false
    })
  }



  getStats(data){
    
    if (this.state.plotIs2D){
      //flatten 2d data
      data = [].concat.apply([], data);
    }
    
    let min;
    let max;
    let mean;
    let stdev;

    if(data.length === 0)
    {
      this.createHist([], 0, 0)
    }
    else
    {
      try{
        data = data.filter(function(e){ return e === 0 || e })

        let min = math.format(math.min(data),{precision: 4})
        let max = math.format(math.max(data),{precision: 4})
        let mean = math.format(math.mean(data),{precision: 4})
        let stdev = math.format(math.std(data),{precision: 4})

        this.createHist(data, min, max)
        this.setState({
          dataStats: {
            min: min,
            max: max,
            mean: mean,
            stdev: stdev,
          }
        })
      }
      catch(err){
        this.createHist([], 0, 0)
        this.setState({
          dataStats: {
            min: null,
            max: null,
            mean: null,
            stdev: null,
          }
        })
      }
    }
  }

  handlePlotChange(eventdata){
    window.setTimeout(function(){
      window.dispatchEvent(new Event('resize'));
      // document.getElementsByClassName('modebar-btn')[4].click()
    }, 100)
    //Back to original zoom   
    let ds = Object.keys(this.state.allData)[0]
    if(ds === undefined) {
        return
    }
    if('xaxis.autorange' in eventdata && 'yaxis.autorange' in eventdata){
      let tempLayout = {...this.state.plotLayout}
      tempLayout.title = this.state.allData[ds].title;
      this.setState({plotLayout:tempLayout})

      if(this.state.plotIs2D){
        this.getStats(this.state.allData[ds].data)

        let xsize = this.state.allData[ds].time.length;
        let ysize = this.state.allData[ds].range.length;
        let i = 1
        while(xsize*ysize > max_size){
            xsize = xsize/i
            ysize = ysize/i
            i+=1
        }
        let dx = i
        let dy = i

        let new_sparse_data = nj.array(this.state.allData[ds].data).slice([null, null, dy], [null, null, dx])
        let new_sparse_times = nj.array(this.state.allData[ds].time).slice([null, null, dx])
        let new_sparse_range = nj.array(this.state.allData[ds].range).slice([null, null, dy])

        let tempPlotData = this.state.plotData

        tempPlotData[0].x = new_sparse_times.tolist()
        tempPlotData[0].y = new_sparse_range.tolist()
        tempPlotData[0].z = new_sparse_data.tolist()

        let tempLayout = this.state.plotLayout
        tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

        this.setState({
          plotData:tempPlotData, 
          plotLayout:tempLayout,
          plotZoom:{
            minXIndex: 0,
            maxXIndex: this.state.allData[ds].time.length,
            minYIndex: 0,
            maxYIndex: this.state.allData[ds].range.length
          }
        })
      }
      else{
        this.setState({
          plotZoom:{
            minXIndex: 0,
            maxXIndex: this.state.allData[ds].time.length,
            minYIndex: 0,
            maxYIndex: this.state.allData[ds].data.length
          }
        })
        this.getStats(this.state.allData[ds].data)
      }
    }

    // Scale data based on zoom
    else if((('xaxis.range[0]' in eventdata && 'xaxis.range[1]' in eventdata) || ('yaxis.range[0]' in eventdata && 'yaxis.range[1]' in eventdata)) ){

      let min_x_ndx = null;
      let max_x_ndx = null;

      let min_y_ndx = null;
      let max_y_ndx = null;

      // If zoomed in X
      let xsize;
      if('xaxis.range[0]' in eventdata && 'xaxis.range[1]' in eventdata){

        let indx = this.state.allData[ds].title.indexOf("for") + 3;
        let new_title = this.state.allData[ds].title.substring(0, indx + 1);
        let min_title_date = this.formatTitleDate(eventdata['xaxis.range[0]'])
        let max_title_date = this.formatTitleDate(eventdata['xaxis.range[1]'])

        new_title =  min_title_date + "-" + max_title_date

        let tempLayout = {...this.state.plotLayout}
        tempLayout.title = new_title
        this.setState({plotLayout:tempLayout})

        let min_date = new Date(eventdata['xaxis.range[0]'].replace(/-/g, '/').split('.')[0])
        let max_date = new Date(eventdata['xaxis.range[1]'].replace(/-/g, '/').split('.')[0])
        min_x_ndx = 0
        max_x_ndx = this.state.allData[ds].time.length-1

        if(this.plotIs2D){
          min_date = this.state.allData[ds].timeObj[this.closest(this.state.allData[ds].timeObj, min_date)]
          max_date = this.state.allData[ds].timeObj[this.closest(this.state.allData[ds].timeObj, max_date)]
        }

        for(let i=0; i<this.state.allData[ds].time.length; i++){
          let curr_date = new Date(this.state.allData[ds].time[i].replace(/-/g, '/').split('.')[0])

          if(curr_date >= min_date && min_x_ndx === 0){
            min_x_ndx = i-1
          }

          if(curr_date >= max_date && max_x_ndx === this.state.allData[ds].time.length-1){
            max_x_ndx = i+2
          }
        }
        if(min_x_ndx < 0){
          min_x_ndx = 0;
        }
        if(max_x_ndx > this.state.allData[ds].time.length-1){
          max_x_ndx = this.state.allData[ds].time.length-1
        }
        xsize = max_x_ndx-min_x_ndx
      }

                
      // If zoomed in Y
      let ysize;
      if('yaxis.range[0]' in eventdata && 'yaxis.range[1]' in eventdata && this.state.plotIs2D){
        let min_range = eventdata['yaxis.range[0]']
        let max_range = eventdata['yaxis.range[1]']
        min_y_ndx = 0
        max_y_ndx = this.state.allData[ds].range.length-1

        min_range = this.state.allData[ds].range[this.closest(this.state.allData[ds].range, min_range)]
        max_range = this.state.allData[ds].range[this.closest(this.state.allData[ds].range, max_range)]

        for(let i=0; i<this.state.allData[ds].range.length; i++){
          let curr_range = this.state.allData[ds].range[i]

          if(curr_range >= min_range && min_y_ndx === 0){
            min_y_ndx = i-1
          }

          if(curr_range >= max_range && max_y_ndx === this.state.allData[ds].range.length-1){
            max_y_ndx = i+1
          }
        }
        if(min_y_ndx < 0){
          min_y_ndx = 0;
        }
        if(max_y_ndx > this.state.allData[ds].range.length){
          max_y_ndx = this.state.allData[ds].range.length
        }

        ysize = max_y_ndx-min_y_ndx
      }



      if(this.state.plotIs2D){       
        let tempPlotData = this.state.plotData

        if(min_x_ndx === null || max_x_ndx === null){
          min_x_ndx = this.state.plotZoom.minXIndex
          max_x_ndx = this.state.plotZoom.maxXIndex
          xsize = max_x_ndx-min_x_ndx
        } 

        if(min_y_ndx === null || max_y_ndx === null){
          min_y_ndx = this.state.plotZoom.minYIndex
          max_y_ndx = this.state.plotZoom.maxYIndex
          ysize = max_y_ndx-min_y_ndx
        }

        let i = 1
        while(xsize*ysize > max_size){
          xsize = xsize/i
          ysize = ysize/i
          i+=1
        }

        let dx = i
        let dy = i

        let new_sparse_data = nj.array(this.state.allData[ds].data).slice([min_y_ndx, max_y_ndx, dy], [min_x_ndx, max_x_ndx, dx])
        let new_sparse_times = nj.array(this.state.allData[ds].time).slice([min_x_ndx, max_x_ndx, dx])
        let new_sparse_range = nj.array(this.state.allData[ds].range).slice([min_y_ndx, max_y_ndx, dy])

        tempPlotData[0].x = new_sparse_times.tolist()
        tempPlotData[0].y = new_sparse_range.tolist()
        tempPlotData[0].z = new_sparse_data.tolist()

        let tempLayout = this.state.plotLayout
        tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 

        this.setState({
          plotData:tempPlotData, 
          plotLayout:tempLayout,
          plotZoom:{
            minXIndex: min_x_ndx,
            maxXIndex: max_x_ndx,
            minYIndex: min_y_ndx,
            maxYIndex: max_y_ndx
          }
        })

        this.getStats(nj.array(this.state.allData[ds].data).slice([min_y_ndx+1, max_y_ndx, null], [min_x_ndx+1, max_x_ndx-1, null]).tolist())
      }
      else{
        this.setState({
          plotZoom:{
            minXIndex: min_x_ndx===null ? this.state.plotZoom.minXIndex : min_x_ndx,
            maxXIndex: max_x_ndx===null ? this.state.plotZoom.maxXIndex : max_x_ndx,
            minYIndex: min_y_ndx===null ? this.state.plotZoom.minYIndex : min_y_ndx,
            maxYIndex: max_y_ndx===null ? this.state.plotZoom.maxYIndex : max_y_ndx
          }
        })
        this.getStats(this.state.allData[ds].data.slice(min_x_ndx+1, max_x_ndx-2))
      }
    }
  }

  handlePlotClick(data){
    if(this.state.timeLoggingIsActive){

      let tempPoints = this.state.timeLoggingPoints
      let currPoint  = moment(data.points[0].x)

      let newLoggingStart = !this.state.timeLoggingStart

      if(this.state.timeLoggingStart){
        tempPoints.push([currPoint])
      }
      else{
        let prevPoint = tempPoints[tempPoints.length - 1][0]

        if(currPoint > prevPoint){
          tempPoints[tempPoints.length - 1].push(currPoint)
        }
        else{
          newLoggingStart = false
        }
      }

      let timeLoggingWindowIsOpen = tempPoints[0].length > 1
      
      this.setState({
        timeLoggingWindowIsOpen: timeLoggingWindowIsOpen,
        timeLoggingStart: newLoggingStart,
        timeLoggingPoints: tempPoints,
      })
    }
  }

  handleLegendDoubleClick = (legItem) => {
    let selectedTrace = legItem.data[legItem.expandedIndex].name
    
    let removeYAxis2 = true

    let spliceIndex = false

    for (let i=0; i<this.state.plotData.length; i++) {
        let pdata = this.state.plotData[i]
        if (pdata.name == selectedTrace) {
            spliceIndex = i
        } 
        else {
          if (pdata.yaxis == "y2") {
            removeYAxis2 = false
          } 
        }
    }

    if (selectedTrace != Object.keys(this.state.allData)[0]) {
      // delete trace and update plot
      let tempLayout = this.state.plotLayout
      let tempPlotData = this.state.plotData
      let tempShareLink = this.state.shareLink

      tempPlotData.splice(spliceIndex, 1)  
      tempLayout['datarevision'] =  tempLayout['datarevision'] + 1 
      if (removeYAxis2) {
        delete tempLayout['yaxis2']
      }

      // create new share link
      let shareLinkStart = tempShareLink.substr(0,tempShareLink.indexOf("["))
      let shareLinkAddVariables = tempShareLink.substr(tempShareLink.indexOf("["), tempShareLink.length-1)
      let addVariablesList = shareLinkAddVariables.replace(/[\[\]']+/g,'').split(",") // remove brakets and split by ,

      let indexToRemove = null
      for (let i=0; i<addVariablesList.length; i++) {
        let currVar = addVariablesList[i]
        if (currVar.includes(selectedTrace.replace(/ *\([^)]*\) */g, "").replace(/\s/g, ""))) { // remove units and whitespace
          indexToRemove = i
        }
      }
      if (indexToRemove != null) {
        addVariablesList.splice(indexToRemove, 1);
      }
      
      if (addVariablesList.length == 0) {
        tempShareLink = shareLinkStart.substr(0,shareLinkStart.indexOf("&addvariables"))
      }
      else {
        shareLinkStart += "["
        for (let i=0; i<addVariablesList.length; i++) {
          let currVar = addVariablesList[i]
          if (i>0){
            shareLinkStart += ","
          }
          shareLinkStart += currVar

        }
        shareLinkStart += "]"
        tempShareLink = shareLinkStart
      }

      this.setState({
          shareLink: tempShareLink,
          plotData: tempPlotData,
          plotLayout: tempLayout
      })
    } 
    else {
      alert('You can not delete your original plot. Click the "Clear Plot" or "Generate Plot" button to start fresh.')
    }
   
    return false
  } 

  handleAfterPlot(){
    var elements = document.getElementsByClassName("legendtext");
    for(let i=0; i<elements.length; i++)
    {
      d3.select(elements[i]).moveToFront();
    }
  }


  handleStartLoadFromQueryString(){
    this.setState({plotIsLoading:true})
  }

  clearPlot() {
    this.setState({
      plotData: [{x: [],y: [],type: 'scatter',mode: 'lines+points',marker: {color: 'red'}}],
      allData: {},
      data_header:'',
      plotLayout: {datarevision:0, autosize: true,  margin: {l: 80,r: 80,b: 80,t: 40}},
      histData: [{x: [],y: [],type: 'histogram'}],
      histLayout: {autosize: true, margin: {l: 80,r: 0,b: 80,t: 10} },
      histLabel: '',
      dataStats: {
        min: null,
        max: null,
        mean: null,
        stdev: null,
      },
      plotIsActive: false, 
      plotIs2D: false,
      customZRange: false,
      shareLink: '',
      plotZoom: {
        minXIndex:0,
        maxXIndex:0,
        minYIndex:0,
        maxYIndex:0
      }
    })
  }

  generatePlot(reqData, is2D, cancel, addToPlot=false, callback=null){
    cancel()

    if(is2D && addToPlot) {
      alert("You may not add 2D data to an existing plot.")
      return
    }

    if(addToPlot) {
      // Check to make sure plot being added is not 2D
      if(is2D) {
        alert("You may not add 2D data to an existing plot.")
        return
      }

      // Check to make sure plot being added is not a duplicate
      for (let i=0; i<this.state.plotData.length; i++) {
        let currTraceName = this.state.plotData[i].name

        if (reqData.ds + ": " + reqData.variable == currTraceName.replace(/ *\([^)]*\) */g, "")) {
          alert("This variable is already plotted.")
          return
        }
      }
    }
    

    this.setState({plotIsLoading:true})
    axios.post(this.state.apiBaseURL + 'get_data_celery', reqData, {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    }).then(res => {
      if(typeof(res) !== "undefined") {
        let result = res.data
        let task_id = result.task_id

        this.checkTaskStatus(task_id, (result) => {

          if('error' in result) {
            this.setState({
              "plotIsLoading": false
            },() => {
              alert("ERROR: " + result['error'])
            })
            return
          }

          let times = result.times;
          let data = result.data;
          let dqrs = result.dqrs
          let title = result.title;
          let ylabel = result.ylabel;
          let variable = result.variable;
          let response = result.dqr_webservice_response;
          let qc_data = result.qc_data;
          let hist_xlabel = result.hist_xlabel;
          let coordlabel = result.coordlabel;
          let units = result.units;
          let header = result.header;

          let regExp = /\(([^)]+)\)/;
          // if(ylabel != ""){
          //   units = regExp.exec(ylabel)[1];
          // }

          let range = [];
          let xsize = 0;
          let ysize = 0;

          if(is2D){
            range = result.range;
            xsize = times.length;
            ysize = range.length;
          }
          else{
            xsize = times.length
            ysize = data.length
          }

          let suspect_dqrs = null
          let missing_dqrs = null
          let incorrect_dqrs = null

          let suspect_dqr_numbers = []
          let missing_dqr_numbers = []
          let incorrect_dqr_numbers = []

          let sus_data = []
          let mis_data = []
          let inc_data = []

          let plot_data = addToPlot ? this.state.plotData : []

          let yaxis = 'yaxis'
          let yaxis_name = 'y1'

          if(plot_data.length > 0 && addToPlot){
            let curr_y1_units = regExp.exec(this.state.plotLayout.yaxis.title.text)[1]


            if(curr_y1_units != units){
              if('yaxis2' in this.state.plotLayout){
                let curr_y2_units = regExp.exec(this.state.plotLayout.yaxis2.title.text)[1]
                // console.log(curr_y2_units, curr_y1_units, units)
                if(curr_y2_units != units){
                  alert('Can not add variable with units: ' + units + '. Please select a different variable that has units ' + curr_y1_units + ' or ' + curr_y2_units)
                  this.setState({
                     plotIsLoading:false
                  })
                  return;
                }
              }
              yaxis = 'yaxis2'
              yaxis_name = 'y2'
            }

          }

          let colors = ['blue','purple']

          if ('suspect' in dqrs){
              suspect_dqrs = dqrs.suspect
          }else{
              suspect_dqr_numbers = null
          }
          if ('missing' in dqrs){
              missing_dqrs = dqrs.missing
          }else{
              missing_dqr_numbers = null
          }
          if ('incorrect' in dqrs){
              incorrect_dqrs = dqrs.incorrect
          }else{
              incorrect_dqr_numbers = null
          }

          for (let key in suspect_dqrs){
              suspect_dqr_numbers.push(key)
              sus_data.push(suspect_dqrs[key].data)
              colors.push("#CCCC00")
          }
          for (let key in missing_dqrs){
              missing_dqr_numbers.push(key)
              mis_data.push(missing_dqrs[key].data)
              colors.push("black")
          }
          for (let key in incorrect_dqrs){
              incorrect_dqr_numbers.push(key)
              inc_data.push(incorrect_dqrs[key].data)
              colors.push("red")
          }

          let display_qc = true

          if (qc_data === -9999) {
            display_qc = false
          } else {
            if(qc_data.every(this.checkNull)){
              display_qc = false
            }
          }

          // Loop through times and change to format expected by plotly
          let times_datetime = []
          let times_objects  = []
          for (let i=0; i < times.length; i++) {
            let userDate = new Date()
            let userOffset = userDate.getTimezoneOffset() * 60000
            let momentoffset = moment(times[i]+userOffset).utcOffset()*60000

            let temp = new Date(times[i]);

            times_objects.push(new Date(temp.getUTCFullYear(), temp.getUTCMonth(), temp.getUTCDate(), temp.getUTCHours(), temp.getUTCMinutes(), temp.getUTCSeconds() ))
            times_datetime.push(this.formatDate(temp))

            if (data[i] === -9999) {
              data[i] = null
            }
          }



          // Prepare data for plotting
          data = data.map(this.checkBad);
          if(is2D && reqData.coordinate === 'all'){
            let xsize = times_datetime.length;
            let ysize = range.length;
            let i = 1
            while(xsize*ysize > max_size){
              xsize = xsize/i
              ysize = ysize/i
              i+=1
            }
            let dx = i
            let dy = i

            let new_sparse_data = nj.array(data).slice([null, null, dy], [null, null, dx])
            let new_sparse_times = nj.array(times_datetime).slice([null, null, dx])
            let new_sparse_range = nj.array(range).slice([null, null, dy])

            plot_data.push({
                z: new_sparse_data.tolist(),
                x: new_sparse_times.tolist(),
                y: new_sparse_range.tolist(),
                colorscale: 'Jet',
                type: 'heatmap',
                name: variable,
                colorbar:{
                    title: hist_xlabel,
                    titleside: 'right',
                },
                zauto: true,
            })
            // this.setState({
            //   plotIs2D: is2D,
            //   // plotData: plot_data,     
            // });

          }
          else{
              plot_data.push({
                  x: times_datetime,
                  y: data,
                  type: 'lines+markers',
                  name: reqData.ds + ': ' + variable + " (" + units + ") " + reqData.coordinate,
                  yaxis: yaxis_name 
              })
          }

         if (display_qc){
            qc_data = qc_data.map(this.checkBad)
            plot_data.push({
              x: times_datetime,
              y: qc_data,
              type: 'lines+markers',
              name: "(Embedded QC)"
            })
          }

          
          for(let x=0; x < sus_data.length; ++x){
            sus_data[x] = sus_data[x].map(this.checkBad)
            plot_data.push({
              x: times_datetime,
              y: sus_data[x],
              type: 'lines+markers',
              name: suspect_dqr_numbers[x]+ " " +"<a href='https://www.archive.arm.gov/ArchiveServices/DQRService?dqrid=" + suspect_dqr_numbers[x] + "'>(Link)</a>",
              marker: {
                color: 'rgb(204, 204, 0)'
              },
              line: {
                color: 'rgb(204, 204, 0)'
              }
            })
          }

          for(let x=0; x < mis_data.length; ++x){
            mis_data[x] = mis_data[x].map(this.checkBad)
            plot_data.push({
              x: times_datetime,
              y: mis_data[x],
              type: 'lines+markers',
              name: missing_dqr_numbers[x] + " " +"<a href='https://www.archive.arm.gov/ArchiveServices/DQRService?dqrid=" + missing_dqr_numbers[x] + "'>(Link)</a>",
              marker: {
                color: 'rgb(0, 0, 0)'
              },
              line: {
                color: 'rgb(0, 0, 0)'
              }
            })
          }

          for(let x=0; x < inc_data.length; ++x){
            inc_data[x] = inc_data[x].map(this.checkBad)
            plot_data.push({
              x: times_datetime,
              y: inc_data[x],
              type: 'lines+markers',
              name: incorrect_dqr_numbers[x] + " " +"<a href='https://www.archive.arm.gov/ArchiveServices/DQRService?dqrid=" + incorrect_dqr_numbers[x] + "'>(Link)</a>",
              marker: {
                  color: 'rgb(255, 0, 0)'
              },
              line: {
                  color: 'rgb(255, 0, 0)'
              }           
            })
          }

          // Determine axis type to use (log/linear) based on user selection
          let axisType = this.state.yAxisLogScale ? 'log' : 'linear'

          // Prepare day/night shading for plotting
          let shading_and_lines = []
          for(let i = 0; (i<result.sun_start.length) && (i<result.sun_end.length); i++){
              if(result.sun_start[i] != -9999 || result.sun_end[i] != -9999){
                  let sun_start = new Date(result.sun_start[i])
                  let sun_end = new Date(result.sun_end[i])

                  let UTC_sun_start = this.formatDate(sun_start)
                  let UTC_sun_end = this.formatDate(sun_end)

                  let curr_shade = {
                      type: 'rect',
                      layer: 'below',
                      xref: 'x',
                      yref: 'paper',
                      x0: UTC_sun_start,
                      y0: 0,
                      x1: UTC_sun_end,
                      y1: 1,
                      fillcolor: 'rgb(255, 255, 220)',
                      opacity: 0.8,
                      line: {
                          width: 0,
                      }
                  }
                  shading_and_lines.push(curr_shade)
                  // highlight_period(sun_start, sun_end)
              }
          }
          // Prepare vertical solar noon lines for plotting
          if(result.solar_noon != -9999){
              for(let i = 0; i < result.solar_noon.length; i++) {
                  let solar_noon = new Date(result.solar_noon[i])

                  let UTC_solar_noon = this.formatDate(solar_noon)

                  let curr_line = {
                      type: 'line',
                      layer: 'below',
                      xref: 'x',
                      yref: 'paper',
                      x0: UTC_solar_noon,
                      y0: 0,
                      x1: UTC_solar_noon,
                      y1: 1,
                      line: {
                          color: '#e9d710',
                          width: 2,
                          dash: 'dash',
                      }
                  }
                  
                  shading_and_lines.push(curr_line)
              }
          }

          let layout = {...this.state.plotLayout}

          // Remove yaxis2 layout if new plot
          if(!addToPlot && 'yaxis2' in this.state.plotLayout){
            delete layout['yaxis2']
          }


          layout['showlegend']   =  true
          layout['legend']       = {bgcolor:'rgba(100, 100, 100, 0.1)',x: 0,y: 1}
          layout['title']        = title
          layout['plot_bgcolor'] = 'rgb(220, 220, 220)'
          layout['autosize']     = true
          layout['shapes']       = shading_and_lines
          layout['xaxis']        = {title: 'Date/Time (UTC)'}
          layout['margin']       = {...layout['margin'], r: yaxis == 'yaxis2' || 'yaxis2' in layout ? 80 : 20}

          layout['datarevision'] = layout['datarevision'] + 1 


          if (yaxis == 'yaxis2'){
            layout[yaxis] = {title: ylabel,type: axisType, side:'right', overlaying:'y'}
          } else{
            layout[yaxis] = {title: ylabel,type: axisType}
          }


          // var tempData = {...this.state.allData}
          let tempData = {}
          let dsName = reqData.ds + ": " + reqData.variable + " (" + units + ") " + reqData.coordinate
          tempData[dsName] = {}
          tempData[dsName].data = data
          tempData[dsName].range = range
          tempData[dsName].title = title
          tempData[dsName].time = times_datetime
          tempData[dsName].timeObj = times_objects

          let shareLink = ""

          if (!addToPlot) {
            let tempURL = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' 
            tempURL = tempURL + 'ds=' + reqData.ds + '&variable=' + reqData.variable + '&sdate=' + moment(reqData.sdate).format('YYYYMMDD') + '&edate=' + moment(reqData.edate).format('YYYYMMDD')
            if('coordinate' in reqData){
              tempURL = tempURL + '&coordinate=' + reqData.coordinate 
            }

            shareLink = tempURL
          } else {
            let tempURL = this.state.shareLink

            let coordStr = ""
            if('coordinate' in reqData && reqData.coordinate != "") {
              coordStr = ":" + reqData.coordinate + ":" + reqData.coord_dim
            } 

            // Add to existing list of addvariables in query string
            if(tempURL.includes('&addvariables')) {
              let startIndex = tempURL.indexOf("]")
              let strToAdd = "," + reqData.ds + ":" + reqData.variable +  coordStr

              tempURL = [tempURL.slice(0, startIndex), strToAdd, tempURL.slice(startIndex)].join('');

            }
            // Append addvariables to existing query string
            else {
              tempURL += "&addvariables=[" + reqData.ds + ":" + reqData.variable + coordStr + "]"
            }

            shareLink = tempURL
          }
          

          // for(let i=0;i<plot_data.length;i++){
          //   console.log(plot_data[i])
          // }

          this.setState({
            histLabel: hist_xlabel,
            plotIs2D: !addToPlot ? is2D : this.state.plotIs2D,
            allData: !addToPlot ? tempData : this.state.allData,
            plotData: plot_data,
            plotLayout: layout,
            plotIsLoading:false,
            plotIsActive: true,
            hasQueryString: false,
            shareLink: shareLink,
            data_header: header,
            plotZoom: {
              minXIndex:0,
              maxXIndex: xsize,
              minYIndex:0,
              maxYIndex: ysize
            }
          }, () => {
            this.getStats(data)

            if (callback != null) {
              callback()
            }
          })
      
        })
      }
    })

    // .catch(error => {
    //   let errMsg = ''
    //   if(error.response.data.message){
    //     errMsg = error.response.data.message
    //   }
    //   else{
    //     errMsg = 'Unknown server error - data may be too large or server may be temporarily down. If problem persists after trying a different data variable and/or shorter time period, please submit a problem report by clicking the "Need Help" button in the bottom right on the page.'
    //   }
    //   console.log(error, error.response)
    //   alert(errMsg)
    //   this.setState({
    //     plotIsLoading: false,
    //     hasQueryString: false
    //   })
    // })
  }

  render() {
    return (
    <div>
      <WarningBanner/>
      <UserFavoritesWindow userFavoritesSortOption={this.state.userFavoritesSortOption} handleUserFavoritesSortChange={this.handleUserFavoritesSortChange} isFetchingFavorites={this.state.isFetchingFavorites} onClose={this.onUserFavoritesWindowClose} user={this.state.currentUser} isOpen={this.state.userFavoritesWindowIsOpen} deleteUserFavorite={this.deleteUserFavorite}/>
      <FileHeaderWindow onClose={this.onFileHeaderWindowClose} header={this.state.data_header} isOpen={this.state.fileHeaderWindowIsOpen}/>
      <LoggingWindow handleTimeLoggingToggle={this.handleTimeLoggingToggle} deleteTimes={this.deleteTimes} data={this.state.timeLoggingPoints} isOpen={this.state.timeLoggingWindowIsOpen}/>

      {this.state.authenticationModalOpen 
        ?
          <LoginModal
            authenticateUser={this.authenticateUser}
            isAuthenticating={this.state.isAuthenticating}
            message={this.state.authenticationMessage}
            toggleAuthModal={this.toggleAuthModal}
          />
        :
        null
      }
      

      <div style={this.state.plotIsLoading ? {display:'block'} : {display:'none'}} className='load-overlay'>
      </div>
      <div style={this.state.plotIsLoading ? {display:'block'} : {display:'none'}} className='centered-div'>
        <p>Loading plot data...</p>
        <FontAwesomeIcon className='load-icon' icon="spinner" size="2x" />
      </div>

      <div id="outer-container">
        <Menu 
          onStateChange={ this.handleMenuChange } 
          isOpen={this.state.menuIsOpen} 
          noOverlay 
          pageWrapId={ "page-wrap" } 
          outerContainerId={ "outer-container" }
        >
          <div className='menu-options'>
            <PlotSelectMenu 
              checkTaskStatus={this.checkTaskStatus}
              componentLoaded={this.state.componentLoaded}
              apiBaseURL={this.state.apiBaseURL}
              hasQueryString={this.state.hasQueryString} 
              queryStringObj={this.state.queryStringObj} 
              timeLoggingStart={this.state.timeLoggingStart} 
              timeLoggingIsActive={this.state.timeLoggingIsActive} 
              handleTimeLoggingToggle={this.handleTimeLoggingToggle} 
              handleLogScaleToggle={this.handleLogScaleToggle} 
              handleCustomZRangeToggle={this.handleCustomZRangeToggle}
              handleZRangeChange={this.handleZRangeChange}
              dataStats={this.state.dataStats}
              customZRange={this.state.customZRange}
              generatePlot={this.generatePlot} 
              plotIsActive={this.state.plotIsActive}
              plotIs2D={this.state.plotIs2D}
              handleStartLoadFromQueryString={this.handleStartLoadFromQueryString}
              shareLink={this.state.shareLink}
              cancelPlotLoad={this.cancelPlotLoad}
              disableLoadFromQueryString={this.disableLoadFromQueryString}
              clearPlot={this.clearPlot}
              currentUser={this.state.currentUser}
              toggleAuthModal={this.toggleAuthModal}
              logOutUser={this.logOutUser}
              displayUserFavoritesWindow={this.displayUserFavoritesWindow}
              addUserFavorite={this.addUserFavorite}
              isAddingToFavorites={this.isAddingToFavorites}
            />
          </div>
        </Menu>
        <main style={{width: this.state.menuIsOpen? 'calc(100% - 300px)': '100%'}} id="page-wrap">

      <div style={this.state.plotData.length > 1 ? {display:'block'} : {display:'none'}} className='multivar-help-container'>
        <p><FontAwesomeIcon style={{color:'red'}} icon="exclamation-circle" size="1x" /> Double click an item in the legend to remove it from the plot.</p>
      </div>
          <InteractivePlot 
            dataStats={this.state.dataStats} 
            onPlotClick={this.handlePlotClick}
            onLegendDoubleClick={this.handleLegendDoubleClick} 
            onAfterPlot={this.handleAfterPlot}
            onRelayout={this.handlePlotChange} 
            plotData={this.state.plotData} 
            plotLayout={this.state.plotLayout} 
            histData={this.state.histData} 
            histLayout={this.state.histLayout}
            displayFileHeaderWindow={this.displayFileHeaderWindow}
            plotIsActive={this.state.plotIsActive}
          />
        </main>
        <HelpButton apiBaseURL={this.state.apiBaseURL}/>
      </div>
    </div>
    );
  }
}

export default App;
