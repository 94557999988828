import React, { useState, useEffect } from 'react';
import axios from 'axios';

import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import './WarningBanner.css';


export const WarningBanner = () => {

    const [tooltipIsOpen, setTooltipIsOpen] = useState(true)
    const [tooltipMessage, setTooltipMessage] = useState("")

    useEffect(() => {
        getWarningText()
    }, [])


    const getWarningText = () => {
        axios.get('https://dq.arm.gov/dqo-webtool-status-files/dq-zoom-status.json', {

        }).then(res => {
            let json = res.data
            setTooltipMessage(json['status_text'])
        })
    }

    const handleClick = () => {
        setTooltipIsOpen(!tooltipIsOpen)
    }


    return(
        <div>
        {tooltipMessage !== "" ?
            <div onClick={handleClick} className='WarningBannerContainer'>
                <Tooltip
                  open={tooltipIsOpen}
                  title={tooltipMessage}
                  position="left"
                >
                    <p><FontAwesomeIcon className="WarningIcon" icon={faExclamationTriangle} size="1x" /></p>
                </Tooltip>
            </div>
            :
            null
        }
        </div>
    )
}