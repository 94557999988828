import React, { Component } from 'react';
import ReactDOM from "react-dom";
import NewWindow from 'react-new-window';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

export default class LoggingWindow extends Component {

  confirmDelete = (next, dropRowKeys) => {

    for(let i=0; i<dropRowKeys.length; i++){
      let startTime = dropRowKeys[i].split(' - ')[0]
      let endTime   = dropRowKeys[i].split(' - ')[1]
      this.props.deleteTimes([startTime,endTime])
    }

    next()
  }

  exportCSV = () => {
    console.log(this.props.data)

    let csv = ''

    let points = this.props.data
    for(let i=0; i<points.length; i++){
      if(points[i].length === 2){
        csv += points[i][0].format('YYYY-MM-DD HH:mm:ss')
        csv += ','
        csv += points[i][1].format('YYYY-MM-DD HH:mm:ss')
        csv += '\n'
      }
    }

    let exportedFilename = "dqzoom_time_log.txt"

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      }
    }
  }

  onWindowUnload = () => {
    this.props.handleTimeLoggingToggle()
  }

  render() {

    let times = []
    let points = this.props.data
    for(let i=0; i<points.length; i++){
      if(points[i].length === 2){
        times.push({'id':i, 'time': points[i][0].format('YYYY-MM-DD HH:mm:ss') + ' - '  + points[i][1].format('YYYY-MM-DD HH:mm:ss')})
      }
    }



    let options = {handleConfirmDeleteRow: this.confirmDelete}

    return(
      this.props.isOpen 
      ? 
      <NewWindow onUnload={this.onWindowUnload} copyStyles={false} title='Time Logging' name='logging_window'>

        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/css/bootstrap.min.css"/>
        <script src="https://code.jquery.com/jquery-2.1.3.min.js"></script>
        <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.2/js/bootstrap.js"></script>

        <div style={{padding:20, width:'100%', height: 'calc(100% - 50px)'}}>
        <button onClick={this.exportCSV} style={{float:'left','fontSize':12}} type="button" className="btn btn-success react-bs-table-csv-btn  hidden-print"><span><i className="fa glyphicon glyphicon-export fa-download"></i> Export to CSV</span></button>
        <BootstrapTable data={times} striped hover condensed options={options}>
                {/*this.props.data.map((points, index) => (
                   <LoggingWindowRow key={index} data={points}/>

                ))*/}
            <TableHeaderColumn isKey={true} dataField="time" dataAlign="center">Time</TableHeaderColumn>
        </BootstrapTable>
        </div>

      </NewWindow>
      :
      null
    )
  }
}

class LoggingWindowRow extends Component {

  render(){
    if(this.props.data.length > 1)
      return(
        <p>{this.props.data[0].format('YYYY-MM-DD HH:mm:ss')} - {this.props.data[1].format('YYYY-MM-DD HH:mm:ss')}</p>
      )
    else{
      return(null)
    }
  }
}